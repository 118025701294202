import { pushDataLayerEvent } from "./index";
import { useStores } from "src/hooks";
import { ENVIRONMENT } from "src/config/environment";
import { SupportedLocalesModel } from "src/models";

export type IdentifyEventPayload = {
  user_id?: number;
  email?: string;
  signup_at?: number;
  lang?: SupportedLocalesModel;
  is_gemcam_user?: boolean;
  last_gemcam_serial_number?: string;
  is_pro_user?: boolean;
  environment?: string;
};

export const pushIdentifyDataLayerEvent = (payload?: IdentifyEventPayload) => {
  const { userStore, subscriptionsStore } = useStores();

  pushDataLayerEvent({
    event: "identify",
    user_id: payload?.user_id || userStore.userMe?.user._id,
    email: payload?.email || userStore.userMe?.user.email,
    signup_at: payload?.signup_at || userStore.userMe?.user.createdAtInEpochSeconds,
    lang: payload?.lang || localStorage.getItem("localeCode"),
    is_gemcam_user:
      payload?.is_gemcam_user != undefined
        ? payload.is_gemcam_user
        : userStore.hasAttemptedGemCamSetup,
    is_pro_user:
      payload?.is_pro_user != undefined
        ? payload.is_pro_user
        : subscriptionsStore.isProSubscription,
    app_container: window.$platform.isApp ? "native" : "browser",
    environment: ENVIRONMENT,
  });
};
