import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import {
  SvgIcon,
  clsx,
  useDidMount,
  useBoolean,
  Button,
  Tooltip,
  Heading,
  Typography,
} from "@gemlightbox/core-kit";

import { YourSubscriptionPlanBadge } from "src/external-ts/components";
import { useStores } from "src/hooks";

import { ReactComponent as ArrowSVG } from "src/external-ts/assets/images/arrow-right-grey.svg";

import styles from "./current-plan-overview.module.css";
import { AvailableSpaceView } from "src/containers/settings/user-storage-info/storage-usage/available-space-view";
import { MediaAmountCard } from "src/containers/settings/user-storage-info/storage-usage/media-amount-card";

export interface CurrentPlanOverviewProps {
  onChangeBillingPeriodClick: VoidFunction;
  onCancelSubscription: VoidFunction;
}

export const CurrentPlanOverview: React.FC<CurrentPlanOverviewProps> = observer(
  ({ onChangeBillingPeriodClick, onCancelSubscription }: CurrentPlanOverviewProps) => {
    const { subscriptionsStore, userStore, localeStore } = useStores();

    const {
      isFreeSubscription,
      isCustomSubscription,
      isStarterSubscription,
      isOldStarterSubscription,
      isProSubscription,
      isProLifetimeSubscription,
      isProLifetimeByOff100,
      getExpireDate,
    } = subscriptionsStore;

    const groupVideoCount = userStore.userStorageUsage?.group.video_count || 0;
    const groupImageCount = userStore.userStorageUsage?.group.image_count || 0;

    const isOpenBoolean = useBoolean();

    const collapseButtonRef = useRef<HTMLButtonElement>(null);

    const handleContactSupport = () => {
      window.open("https://picupmedia.com/contact-us/?contact-support", "_blank");
    };

    useDidMount(() => userStore.loadStorageUsage());

    return (
      <>
        <div className={styles.container}>
          <div className={styles.left}>
            <Button
              appearance="tertiaryOutlined"
              forwardRef={collapseButtonRef}
              onClick={isOpenBoolean.trigger}
            >
              <SvgIcon
                className={clsx(styles.collapseIcon, { [styles.opened]: isOpenBoolean.value })}
                icon={ArrowSVG}
              />
            </Button>
            <Heading tag="h4" color="textSecondary">
              {localeStore.t('settings.subscriptions.components["current-plan-overview"].title')}
            </Heading>
            <YourSubscriptionPlanBadge hideInterval={isOldStarterSubscription} />
          </div>
          <div className={styles.right}>
            {(isProLifetimeByOff100 || isCustomSubscription) && (
              <Button appearance="secondaryGhost" onClick={handleContactSupport}>
                {localeStore.t(
                  'settings.subscriptions.components["current-plan-overview"].buttons.contact',
                )}
              </Button>
            )}
            {!isProLifetimeSubscription && (isProSubscription || isStarterSubscription) && (
              <Button appearance="secondaryGhost" onClick={onChangeBillingPeriodClick}>
                {localeStore.t(
                  'settings.subscriptions.components["current-plan-overview"].buttons.change',
                )}
              </Button>
            )}
            {!isProLifetimeSubscription && !isFreeSubscription && !isCustomSubscription && (
              <Button appearance="errorGhost" onClick={onCancelSubscription}>
                {localeStore.t(
                  'settings.subscriptions.components["current-plan-overview"].buttons.cancel',
                )}
              </Button>
            )}
          </div>
        </div>
        <Tooltip
          className={styles.tooltipContainer}
          position={localeStore.isLTR ? "bottomLeft" : "bottomRight"}
          offsetY={14}
          target={collapseButtonRef}
          onClose={isOpenBoolean.setValue}
          isOpen={isOpenBoolean.value}
        >
          <div className={styles.tooltipContent}>
            <Typography className={styles.tooltipTitle} size="medium600" color="textSecondary">
              {localeStore.t(
                'settings.subscriptions.components["current-plan-overview"].tooltip.title',
              )}
            </Typography>
            <div className={styles.tooltipDetailsContainer}>
              <div className={styles.planDetailsContainer}>
                {!isFreeSubscription && (
                  <div className={styles.nextPaymentContainer}>
                    <Typography size="small" color="textTertiary">
                      {localeStore.t(
                        'settings.subscriptions.components["current-plan-overview"].tooltip["next-payment"]',
                      )}
                    </Typography>
                    <Typography className={styles.textValue} size="small600" color="textSecondary">
                      {isProLifetimeSubscription
                        ? localeStore.t(
                            'settings.subscriptions.components["current-plan-overview"].tooltip.none',
                          )
                        : getExpireDate(true)}
                    </Typography>
                  </div>
                )}
                <div className={styles.mediaAmountContainer}>
                  <MediaAmountCard
                    label={localeStore.t('settings["user-storage-media-labels"].videos')}
                    value={groupVideoCount}
                  />
                  <MediaAmountCard
                    label={localeStore.t('settings["user-storage-media-labels"].photos')}
                    value={groupImageCount}
                  />
                  <MediaAmountCard
                    label={localeStore.t('settings["user-storage-media-labels"].members')}
                    value={userStore.userMe?.countOfUsers || 0}
                  />
                </div>
              </div>
              <AvailableSpaceView />
            </div>
          </div>
        </Tooltip>
      </>
    );
  },
);

export default CurrentPlanOverview;
