import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  SvgIcon,
  Modal,
  Heading,
  Typography,
  isFunction,
  useDidUpdate,
  useFetch,
  capitalize,
  Tooltip,
  useBoolean,
  clsx,
  globalStyles,
  useQuery,
} from "@gemlightbox/core-kit";

import { getUserBalance } from "src/api";
import { useStores } from "src/hooks";
import { ModalExtendedType } from "src/store";
import { proPlanIdV2, subscriptionsData } from "../../subscriptions.constants";
import { SupportedSubscriptionInterval } from "../../subscriptions.types";
import { SubaccountsOfferCounter } from "../content/components/subaccounts-offer-counter";
import { addExtraTooltipId, subExtraTooltipId } from "./billing-modal.constants";
import { BillingMonthly, BillingYearly, BillingLifetime } from "./components";
import { useBilling } from "./billing-modal.utils";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";
import { ReactComponent as CrownSVG } from "src/external-ts/assets/images/crown-pro-plan.svg";
import { ReactComponent as FlagSVG } from "src/external-ts/assets/images/flag.svg";
import styles from "./billing-modal.module.css";
import { useNavigate } from "react-router-dom";
import { BillingDowngrade } from "./components/billing-downgrade";
import {
  handleSubaccountsCheckNumber,
  translate,
} from "src/containers/settings/subscriptions/components/content/components/pro-offer/pro-offer.utils";

export type BillingExtraData = { extraCount: number; coupon?: string; bluetoothName?: string };

export type BillingModalProps = ModalExtendedType<{
  offer: "starter" | "pro";
  subscriptionType: SupportedSubscriptionInterval;
  canDisplayAddons?: boolean;
  isPaymentDisabled?: boolean;
  promoCodeRequired?: boolean;
  activateProPlanPage?: boolean;
  onProceed: (data: BillingExtraData) => void;
  extraCountValue?: number;
  onExtraCountChange?: (value: number) => void;
}>;

export const BillingModal: React.FC<BillingModalProps> = observer(
  ({ isOpen, setClose, options, onFinalClosed }: BillingModalProps) => {
    const {
      offer,
      subscriptionType,
      canDisplayAddons = false,
      isPaymentDisabled = false,
      promoCodeRequired = false,
      activateProPlanPage = false,
      onProceed,
      extraCountValue = 1,
      onExtraCountChange,
    } = options;

    const addExtraTooltipBoolean = useBoolean();
    const subtractExtraTooltipBoolean = useBoolean();
    const isLoadingBoolean = useBoolean(false);

    const { subscriptionsStore, subaccountsStore, localeStore } = useStores();

    const {
      subGbAddonsAmount,
      isFreeSubscription,
      isStarterSubscription,
      isProSubscription,
      proPlan,
      proPlanPrices,
      starterPlan,
      starterPlanPrices,
      subaccountAddonPrices,
      planInterval,
      isProLifetimeSubscription,
      proPlanV2,
      currentPlan,
    } = subscriptionsStore;

    const {
      query: { bluetooth_name },
    } = useQuery();

    const useBalanceFetch = useFetch(getUserBalance);
    const balance = useBalanceFetch.payload || 0;

    const [coupon, setCoupon] = useState("");
    const [couponError, setCouponError] = useState("");

    const [extraCount, setExtraCount] = useState(Math.max(extraCountValue, 0));
    const {
      title,
      subtitle,
      monthlyToYearly,
      yearlyToMonthly,
      upgrading,
      currentSubscription,
      downgrade,
    } = useBilling({ subscriptionType, extraCount });
    useDidUpdate(() => setExtraCount(Math.max(extraCountValue, 0)), [extraCountValue]);

    const matchPlan = currentPlan?.plan_id === proPlanIdV2 ? proPlanV2 : proPlan;

    const shouldDisableByProSubaccountsAmount =
      isStarterSubscription &&
      subaccountsStore.subaccountList.length >
        extraCount + Number(matchPlan?.metadata?.subaccounts || 0);

    const shouldDisableBySTarterSubaccountsAmount =
      isProSubscription &&
      subaccountsStore.subaccountList.length >
        extraCount + Number(starterPlan?.metadata?.subaccounts || 0);

    const shouldDisableBySubaccountsAmount =
      offer === "pro"
        ? shouldDisableByProSubaccountsAmount
        : offer === "starter"
        ? shouldDisableBySTarterSubaccountsAmount
        : false;

    const isPaymentButtonDisabled =
      isPaymentDisabled ||
      shouldDisableBySubaccountsAmount ||
      (!isFreeSubscription &&
        subGbAddonsAmount === extraCount &&
        currentSubscription === offer &&
        planInterval === subscriptionType);
    const currentExtraCount = extraCount - subGbAddonsAmount;

    const yearlyExtraPrice = extraCount * subaccountAddonPrices.yearlyPrice;
    const currentExtraPrice = (extraCount - subGbAddonsAmount) * subaccountAddonPrices.yearlyPrice;

    const planSubaccountsUnit = Number(
      offer === "pro"
        ? matchPlan?.metadata?.subaccounts || 0
        : starterPlan?.metadata?.subaccounts || 0,
    );

    const planStorageUnit = Number(
      offer === "pro"
        ? matchPlan?.metadata?.limit_storage || 0
        : starterPlan?.metadata?.limit_storage || 0,
    );

    const getMonthlyPrice = (offer: "pro" | "starter") => {
      if (offer === "pro") {
        const monthlyPrice = activateProPlanPage
          ? proPlanPrices.monthlyActivatePrice
          : proPlanPrices.monthlyPrice;
        return monthlyPrice + extraCount * subaccountAddonPrices.monthlyPrice;
      } else {
        return starterPlanPrices.monthlyPrice + extraCount * subaccountAddonPrices.monthlyPrice;
      }
    };

    const getYearlyPrice = (offer: "pro" | "starter") => {
      if (offer === "pro") {
        return proPlanPrices.yearlyPrice + yearlyExtraPrice;
      } else {
        return starterPlanPrices.yearlyPrice + yearlyExtraPrice;
      }
    };

    const getPriceBySubscriptionType = (subscriptionType: SupportedSubscriptionInterval) => {
      if (subscriptionType === "lifetime") return yearlyExtraPrice;
      if (subscriptionType === "yearly") return getYearlyPrice(offer);
      if (subscriptionType === "monthly") return getMonthlyPrice(offer);
      return 0;
    };

    const navigate = useNavigate();
    const handleProceed = () => {
      if (!bluetooth_name && promoCodeRequired && !coupon) {
        setCouponError("Promo code is required");
        return;
      }
      if (downgrade) {
        handleSubaccountsCheckNumber(
          extraCount,
          () => {
            setClose();
            navigate("/settings/subaccounts");
          },
          () => {
            isLoadingBoolean.setTruthy();
            onProceed({ extraCount, coupon, bluetoothName: bluetooth_name });
          },
        );
      } else {
        onProceed({ extraCount, coupon, bluetoothName: bluetooth_name });
      }
    };

    const handleChangeCoupon = (value: string) => {
      setCouponError("");
      setCoupon(value);
    };

    const handleExtraCountChange = (value: number | React.SetStateAction<number>) => {
      const newValue = isFunction(value) ? value(extraCount) : value;
      setExtraCount(newValue);
      onExtraCountChange?.(newValue);
    };

    const handleSubtractExtraCount = () => {
      handleExtraCountChange((prevState) => Math.max(prevState - 1, 0));
    };

    const handleAddExtraCount = () => handleExtraCountChange((prevState) => prevState + 1);

    const offerIcon = offer === "pro" ? CrownSVG : FlagSVG;

    return (
      <Modal
        scrollWrapperClassName={styles.billingModalScrollWrapper}
        contentClassName={styles.billingModalContent}
        isOpen={isOpen}
        setClose={setClose}
        onFinalClosed={onFinalClosed}
        disableBorderRadius
        disablePadding
        withCross
      >
        <div className={styles.modalHeader}>
          <Heading className={styles.modalTitle} tag="h3" color="textSecondary">
            {title}
          </Heading>
          {subtitle && (
            <Typography size="small" color="textTertiary">
              {subtitle}
            </Typography>
          )}
        </div>
        <div className={clsx(styles.billingContent, globalStyles.addScrollStyles)}>
          <div className={styles.left}>
            <div className={styles.offerHeader}>
              <div className={styles.offer}>
                <SvgIcon
                  className={offer === "pro" ? styles.proIcon : styles.starterIcon}
                  icon={offerIcon}
                  size={32}
                  disableAutoColor
                />
                <Typography className={styles.offerTitle} size="medium600" color="textSecondary">
                  {capitalize(offer)}
                </Typography>
              </div>
              <Typography className={styles.offerInterval} size="medium600">
                {localeStore.t(
                  `components.business["subscription-badges"].intervals["${subscriptionType}"]` as LocaleCodeTypes,
                )}
              </Typography>
            </div>
            <div className={styles.featureList}>
              {canDisplayAddons && (
                <>
                  <div className={styles.offerItemWrapper}>
                    <div className={styles.subaccountItemTextContainer}>
                      <Typography
                        className={styles.offerItemName}
                        size="extraSmall"
                        color="textTertiary"
                      >
                        {localeStore.t(
                          'settings.subscriptions.components.content["offer-features"].subaccounts',
                        )}
                      </Typography>
                      <Typography
                        className={styles.offerItemName}
                        size="extraSmall"
                        color="textTertiary"
                      >
                        {localeStore.t(
                          'settings.subscriptions.components.content["offer-features"].storage',
                        )}
                      </Typography>
                    </div>

                    <SubaccountsOfferCounter
                      containerClassName={styles.subaccountCounterContainer}
                      extraCount={extraCount}
                      planSubaccountsUnit={planSubaccountsUnit}
                      planStorageUnit={planStorageUnit}
                      isCustomSubscription={false}
                      subaccountAddonPrices={subaccountAddonPrices}
                      onAddExtraCount={handleAddExtraCount}
                      onSubtractExtraCount={handleSubtractExtraCount}
                      subscriptionToSelect={subscriptionType}
                    />
                  </div>
                </>
              )}
              {subscriptionsData.features.map(
                ({ name, proPlanAmount, starterPlanAmount }, index) => (
                  <div key={index} className={styles.offerItemWrapper}>
                    <Typography
                      className={styles.offerItemName}
                      size="extraSmall"
                      color="textTertiary"
                    >
                      {localeStore.t(
                        `settings.subscriptions.components.content["offer-features"][${name
                          .replaceAll(" ", "")
                          .toLocaleLowerCase()}]` as LocaleCodeTypes,
                      )}
                    </Typography>
                    <Typography
                      className={styles.offerItemValue}
                      size="extraSmall500"
                      color="textSecondary"
                    >
                      {offer === "pro" ? translate(proPlanAmount) : starterPlanAmount}
                    </Typography>
                  </div>
                ),
              )}
            </div>
          </div>
          <div className={styles.right}>
            {downgrade ? (
              <BillingDowngrade
                price={getPriceBySubscriptionType(subscriptionType)}
                subscriptionType={subscriptionType}
              />
            ) : (
              <React.Fragment>
                {!isProLifetimeSubscription && subscriptionType === "monthly" && (
                  <BillingMonthly
                    coupon={coupon}
                    monthlyPrice={getMonthlyPrice(offer)}
                    yearlyToMonthly={yearlyToMonthly}
                    upgrading={upgrading}
                    onCouponChange={handleChangeCoupon}
                    promoCodeRequired={promoCodeRequired}
                    promoCodeError={couponError}
                    activateProPlanPage={activateProPlanPage}
                  />
                )}
                {!isProLifetimeSubscription && subscriptionType === "yearly" && (
                  <BillingYearly
                    coupon={coupon}
                    balance={balance}
                    yearlyPrice={getYearlyPrice(offer)}
                    monthlyToYearly={monthlyToYearly}
                    upgrading={upgrading}
                    onCouponChange={handleChangeCoupon}
                    promoCodeError={couponError}
                    promoCodeRequired={promoCodeRequired}
                  />
                )}
                {isProLifetimeSubscription && (
                  <BillingLifetime
                    coupon={coupon}
                    yearlyPrice={yearlyExtraPrice}
                    currentExtraPrice={currentExtraPrice}
                    onCouponChange={handleChangeCoupon}
                    promoCodeError={couponError}
                    promoCodeRequired={promoCodeRequired}
                  />
                )}
              </React.Fragment>
            )}
            <Button
              loading={isLoadingBoolean.value}
              onClick={handleProceed}
              disabled={isPaymentButtonDisabled && currentExtraCount === 0}
            >
              {downgrade
                ? localeStore.t("settings.subscriptions.components.content.buttons.downgrade")
                : localeStore.t(
                    'settings.subscriptions.components["billing-modal"].buttons["go-to-payment"]',
                  )}
            </Button>
          </div>
        </div>

        <Tooltip
          appearance="secondary"
          position="bottom"
          target={subExtraTooltipId}
          onClose={subtractExtraTooltipBoolean.setValue}
          isOpen={subtractExtraTooltipBoolean.value}
          withAngle
        >
          <Typography size="extraSmall500">
            - US <strong>${subaccountAddonPrices?.monthlyPrice || ""}</strong>
            /mo&nbsp;
            {localeStore.t('common.settings["extra-offer"]["extra-user"]')}&nbsp;&&nbsp;
            <strong>50GB</strong>
          </Typography>
        </Tooltip>
        <Tooltip
          appearance="secondary"
          position="bottom"
          target={addExtraTooltipId}
          onClose={addExtraTooltipBoolean.setValue}
          isOpen={addExtraTooltipBoolean.value}
          withAngle
        >
          <Typography size="extraSmall500">
            + US<strong>${subaccountAddonPrices?.monthlyPrice || ""}</strong>
            /mo&nbsp;
            {localeStore.t('common.settings["extra-offer"]["extra-user"]')}&nbsp;&&nbsp;
            <strong>50GB</strong>
          </Typography>
        </Tooltip>
      </Modal>
    );
  },
);

export default BillingModal;
