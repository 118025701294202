import React, { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  base64ToBlob,
  clsx,
  imageSourceToCanvas,
  loadImage,
  NotificationTitle,
  Nullable,
  uniqId,
  useDidUnmount,
  useDidUpdate,
  rotateImageTrimmed,
  toRadians,
  useDidMount,
} from "@gemlightbox/core-kit";

import { MediaModel, ProductTypeModel } from "src/models";
import { getMedia, postClassifyImage } from "src/api";
import { useStores } from "src/hooks";
import { removeImageBackground } from "src/utils";
import { MediaEditor } from "./media-editor";
import { rotateImg } from "./media-editor/media-editor.utils";
import { supportedTypes } from "./ar-media.constants";
import { ArMediaType } from "./ar-media.types";

import styles from "./ar-media.module.css";

export const ArMediaPage: React.FC = observer(() => {
  const { mediaId, productTypeName } = useParams<{ mediaId: string; productTypeName: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [media, setMedia] = useState<Nullable<MediaModel>>(null);
  const data = location.state as Nullable<ArMediaType>;
  const selectedMedia = useMemo(() => {
    return data?.type === "media" ? data.media : media;
  }, [data, media]);
  const predefinedProductType = useMemo(() => {
    return selectedMedia?.arData?.productType || selectedMedia?.productType;
  }, [selectedMedia]);
  const initialProductType = data?.productType?.name || productTypeName;
  const isInEditAR = !!predefinedProductType;

  const { notificationStore, localeStore, categoriesStore, userStore } = useStores();

  const [mainImage, setMainImage] = useState<Nullable<HTMLCanvasElement>>(null);
  const [productType, setProductType] = useState<Nullable<ProductTypeModel>>(null);

  const handleGoBack = (mediaId?: Nullable<number>) => {
    userStore.parentPostMessageClosed({ mediaId });
    navigate(-1);
  };

  const handleConfirmClose = () => {
    notificationStore.open({
      title: (
        <NotificationTitle>
          {localeStore.t('["ar-media"]["notification-text"].top')}
          <br />
          {localeStore.t('["ar-media"]["notification-text"].bottom')}
        </NotificationTitle>
      ),
      confirmText: localeStore.t("common.buttons.close"),
      cancelText: localeStore.t("common.buttons.cancel"),
      icon: "exclamation",
      onOk: () => handleGoBack(),
      dataCy: "ar-media-modal-close-notification",
    });
  };

  const productTypes = useMemo(() => {
    return categoriesStore.defaultCategories
      .filter(({ name, showInARFlow }) => {
        return showInARFlow && supportedTypes.indexOf(name) !== -1;
      })
      .sort((a, b) => a.position - b.position);
  }, [categoriesStore.defaultCategories]);

  const handleGetProductType = (productTypeName: Nullable<string>) => {
    return (
      productTypes.find((el) => {
        // NOTE: condition below because we get pendant from product type recognize algo, not pendants.
        if (productTypeName === "pendant" && el.name === "Pendants") return true;
        return el.name.toLowerCase() === productTypeName?.toLowerCase();
      }) || productTypes[0]
    );
  };

  useDidMount(async () => {
    if (!mediaId) return;

    notificationStore.openLoader({
      loaderType: "diamond-loader",
      appearance: "secondary",
      title: localeStore.t('["ar-media"]["media-editor"].loader.title'),
      subtitle: localeStore.t('["ar-media"]["media-editor"].loader.subtitle'),
    });

    const { success } = await getMedia.getRequest({ params: { mediaId } }).fetch();
    setMedia(success);
  });

  useDidUpdate(
    async () => {
      if (mediaId && !media) return;
      if (!selectedMedia) return handleGoBack();
      if (!productTypes.length) return;

      notificationStore.openLoader({
        loaderType: "diamond-loader",
        appearance: "secondary",
        title: localeStore.t('["ar-media"]["media-editor"].loader.title'),
        subtitle: localeStore.t('["ar-media"]["media-editor"].loader.subtitle'),
      });

      if (isInEditAR) {
        const blinkFile =
          selectedMedia?.arData?.blinkFile?.original || selectedMedia?.blinkFile?.original;
        if (!blinkFile) return handleGoBack();
        const blinkImage = await loadImage(blinkFile, true);

        const offsetDegrees = Number(
          selectedMedia?.arData?.arOffsetRotation || selectedMedia?.arOffsetRotation || 0,
        );

        const offsetRotatedImage = rotateImageTrimmed(blinkImage, toRadians(offsetDegrees));

        setProductType(handleGetProductType(predefinedProductType));
        setMainImage(offsetRotatedImage);
        notificationStore.closeLoader();
        return;
      }
      if (!selectedMedia?.file) return handleGoBack();
      const mediaImage = await loadImage(selectedMedia.file.original, true);

      removeImageBackground(mediaImage, {
        directRequest: true,
        cropped: true,
        rawBoundary: true,
      })
        .then(async (removedBGImage) => {
          const removedBGCanvas = imageSourceToCanvas(removedBGImage.image);

          if (initialProductType) {
            const productType = handleGetProductType(initialProductType);
            setProductType(productType);
            setMainImage(rotateImg(productType, removedBGCanvas));
            notificationStore.closeLoader();
            return;
          }

          const base64 = removedBGCanvas.toDataURL();
          const blob = base64ToBlob(base64.split(",")[1], "image/png");
          const fileName = uniqId() + ".jpeg";
          const file = new File([blob], fileName, { type: "image/png" });

          const formData = new FormData();
          formData.append("file", file);

          const classifyRes = await postClassifyImage.getRequest({ data: formData }).fetch();
          const predictedProductType = classifyRes?.success?.predicted_class;

          const productType = handleGetProductType(predictedProductType);
          setProductType(productType);
          setMainImage(rotateImg(productType, removedBGCanvas));
          notificationStore.closeLoader();
        })
        .catch((error) => {
          console.error(error);
          notificationStore.open({
            title: localeStore.t('camera["camera-preview-sidebar"]["error-remove-bg"].title'),
            confirmText: localeStore.t("common.buttons.confirm"),
            cancelText: "",
            confirmAppearance: "secondary",
            icon: "exclamation",
            onlyConfirm: true,
            onOk: () => navigate(-1),
          });
        });
    },
    [productTypes.length, media, predefinedProductType],
    true,
  );

  useDidUnmount(() => {
    try {
      notificationStore.closeLoader();
    } catch (error) {}
  });

  return (
    <main className={clsx(styles.container, styles.secondStep)}>
      {mainImage && productType && (
        <MediaEditor
          data={selectedMedia}
          productTypes={productTypes}
          productType={productType}
          mainImage={mainImage}
          setProductType={setProductType}
          handleGoBack={handleConfirmClose}
          onClose={handleConfirmClose}
        />
      )}
    </main>
  );
});
