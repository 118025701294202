import React from "react";
import { observer } from "mobx-react-lite";
import { Button, CommandAction, Image, SvgIcon, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { editMediaStore } from "../../../edit-media.store";
import { GroupBlock } from "../../group-block";

import { ReactComponent as CheckmarkSVG } from "src/external-ts/assets/images/checkmark-circle-hollow-grey.svg";
import styles from "./recent-pictures.module.css";

export const RecentPictures: React.FC = observer(() => {
  const { uploadedBGs, currentUploadedBG } = editMediaStore;

  const { localeStore } = useStores();

  const handleSelectImage = (objectUrl: string) => async () => {
    const backgroundImageComponent = editMediaStore.backgroundImageComponent;
    const undoUploadedBG = editMediaStore.currentUploadedBG;
    const undoTransform = backgroundImageComponent.transform.clone();
    const undoTexture = backgroundImageComponent.state.texture.clone();
    const undoDisplay = backgroundImageComponent.style.display;
    await editMediaStore.selectBGImage(objectUrl);
    const actionUploadedBG = editMediaStore.currentUploadedBG;
    const actionTransform = backgroundImageComponent.transform.clone();
    const actionTexture = backgroundImageComponent.state.texture.clone();
    const actionDisplay = backgroundImageComponent.style.display;
    editMediaStore.renderer.commands.execute(
      new CommandAction(
        "Uploaded background change",
        () => {
          editMediaStore.currentUploadedBG = actionUploadedBG;
          backgroundImageComponent.transform.copy(actionTransform);
          backgroundImageComponent.state.texture = actionTexture;
          backgroundImageComponent.style.display = actionDisplay;
          editMediaStore.renderer.selectComponent(backgroundImageComponent);
        },
        () => {
          editMediaStore.currentUploadedBG = undoUploadedBG;
          backgroundImageComponent.transform.copy(undoTransform);
          backgroundImageComponent.state.texture = undoTexture;
          backgroundImageComponent.style.display = undoDisplay;
          editMediaStore.renderer.selectComponent(backgroundImageComponent);
        },
      ),
    );
  };

  if (!uploadedBGs.length) return null;

  return (
    <GroupBlock className={styles.recentPicturesBlockGroup}>
      <Typography className={styles.recentPicturesTitle} size="small600" color="textSecondary">
        {localeStore.t('["edit-media"].inspector.background["recent-pictures"]')}
        <Typography size="extraSmall" color="textTertiary">
          {localeStore.t('["edit-media"]["max-10"]')}
        </Typography>
      </Typography>

      <div className={styles.picturesWrapper}>
        {uploadedBGs.map((objectUrl) => (
          <Button
            key={objectUrl}
            className={styles.picture}
            appearance="unset"
            size="unset"
            onClick={handleSelectImage(objectUrl)}
            disabled={editMediaStore.isProcessingImage}
          >
            <Image src={objectUrl} />
            {currentUploadedBG === objectUrl && (
              <SvgIcon className={styles.checkmark} icon={CheckmarkSVG} size={25} />
            )}
          </Button>
        ))}
      </div>
    </GroupBlock>
  );
});

export default RecentPictures;
