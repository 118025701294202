import React from "react";
import { observer } from "mobx-react-lite";
import { CommandAction, Range, Typography } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { editMediaStore } from "../../../edit-media.store";
import { GroupBlock } from "../../group-block";

import styles from "./opacity.module.css";

export const Opacity: React.FC = observer(() => {
  const { backgroundImageComponent, renderer } = editMediaStore;

  const { localeStore } = useStores();

  const opacityLabelFormatter = (value: number) => {
    return Math.round(value * 100) + "%";
  };

  const handleOpacityChange = (value: number) => {
    backgroundImageComponent.state.filters.u_opacity = value;
  };

  const handleOpacityChangeFinal = (value: number, _: number, prevValue: number) => {
    renderer.commands.execute(
      new CommandAction(
        "Background opacity change",
        () => {
          backgroundImageComponent.state.filters.u_opacity = value;
        },
        () => {
          backgroundImageComponent.state.filters.u_opacity = prevValue;
        },
      ),
    );
  };

  return (
    <GroupBlock className={styles.opacityBlockGroup}>
      <Typography size="small600" color="textSecondary">
        {localeStore.t('["edit-media"].inspector.background.opacity')}
      </Typography>

      <Range
        label={localeStore.t('["edit-media"].inspector.background["opacity-tool"]')}
        min={0}
        max={1}
        step={0.01}
        roundValue={2}
        value={backgroundImageComponent.state.filters.u_opacity}
        onChange={handleOpacityChange}
        onFinalChange={handleOpacityChangeFinal}
        messageFormatter={opacityLabelFormatter}
        disableFinalCallOnSameValue
        disableError
        showRangeValue
      />
    </GroupBlock>
  );
});

export default Opacity;
