import { ObjectType, TextEditorUtils, isEmpty } from "@gemlightbox/core-kit";
import {
  AttributeModel,
  ProductParameterModel,
  ProductParameterRequestModel,
  AttributeType,
} from "src/models";

export const getAttributesForRequest = (
  values: ObjectType,
  attributes: AttributeModel[],
  productAttributes?: ProductParameterModel[],
): ProductParameterRequestModel[] =>
  Object.entries(values).reduce<ProductParameterRequestModel[]>((acc, [key, value]) => {
    const attribute = attributes.find(({ name }) => name === key);
    if (!attribute) return acc;

    let resultVal = value;

    if (attribute.type === AttributeType.multiselect) {
      resultVal = Array.isArray(resultVal) ? resultVal.join(", ") : resultVal;
    } else if (attribute.name === "description") {
      resultVal = TextEditorUtils.serializeEditorValue(resultVal);

      const productDescription = productAttributes?.find(
        (attribute) => attribute.name === "description",
      )?.value;

      const newText = TextEditorUtils.stringifyEditorValue(
        TextEditorUtils.normalizeEditorValue(resultVal),
      );
      const oldText = TextEditorUtils.stringifyEditorValue(
        TextEditorUtils.normalizeEditorValue(productDescription || ""),
      );

      if (newText === oldText) {
        resultVal = oldText;
        return acc;
      } else {
        resultVal = newText;
      }
    } else {
      resultVal = resultVal?.toString();
    }

    if (isEmpty(resultVal)) {
      resultVal = null;
    }

    return [...acc, { attribute_id: attribute.id, value: resultVal }];
  }, []);
