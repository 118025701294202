import { Nullable, ObjectType, ObjectValues } from "@gemlightbox/core-kit";

import {
  MediaTypeKeys,
  GlobalImageDataModel,
  GlobalLinkModel,
  AttributeModel,
  MediaBlinkData,
  DeviceMetaDataModel,
  PendingUploadStatus,
} from "src/models";

export enum ProductType {
  bracelets = "Bracelets",
  brooches = "Brooches",
  diamonds = "Diamonds",
  earrings = "Earrings",
  gemstones = "Gemstones",
  rings = "Rings",
  necklaces = "Necklaces",
  pendants = "Pendants",
  watches = "Watches",
}

export enum ProductsExportType {
  pdf = "pdf",
  csv = "csv",
  xlsx = "xlsx",
}

export enum LanguageType {
  en = "en",
  es = "es",
  zh = "zh",
  zh_tw = "zh_TW",
  hi = "hi",
  ar = "ar",
  fr = "fr",
  ja = "ja",
  ko = "ko",
  de = "de",
  it = "it",
  tr = "tr",
  th = "th",
  he = "he",
}

export const productTypesSingularNames: Record<ProductType, string> = {
  [ProductType.bracelets]: "Bracelet",
  [ProductType.brooches]: "Brooch",
  [ProductType.diamonds]: "Diamond",
  [ProductType.earrings]: "Earring",
  [ProductType.gemstones]: "Gemstone",
  [ProductType.rings]: "Ring",
  [ProductType.necklaces]: "Necklace",
  [ProductType.pendants]: "Pendant",
  [ProductType.watches]: "Watch",
};

export const productDefaultTypeKeys = [
  "all",
  ...Object.values(ProductType),
  ...Object.values(productTypesSingularNames),
];

export const productDefaultTypesNames = productDefaultTypeKeys.reduce(
  (accumulator: any, value: string) => {
    accumulator[value.toLocaleLowerCase()] = value.toLocaleLowerCase();
    return accumulator;
  },
  {},
);

export type ProductTypeKeys = keyof typeof ProductType;

export type ProductTypeValues = ObjectValues<typeof ProductType>;

export type ProductImageModel = {
  type: MediaTypeKeys;
  id: number;
  arData?: MediaBlinkData;
  file?: GlobalImageDataModel;
  metaData?: DeviceMetaDataModel;
  status?: Nullable<PendingUploadStatus>;
};

export type ProductParameterModel = AttributeModel & {
  value: Nullable<string>;
};

export type ProductParameterRequestModel = {
  attribute_id: number;
  from?: number;
  to?: number;
  value?: Nullable<string | number>;
  type?: AttributeModel["type"];
  name?: AttributeModel["name"];
};

export type ProductDataType = "product" | "variant";

export type ProductModel = {
  title: string;
  optionsTitle?: string;
  creator: string | null;
  description: string | null;
  detailedTitle: string | null;
  stoneType: string | null;
  productType: ProductTypeKeys | string;
  caratWeight: number | null;
  dataType: ProductDataType;
  _id: number;
  user_id: number | null;
  discount: number;
  price: number | null;
  sumPrice: number | null;
  quantity: number | null;
  assignedToCatalogs: number[];
  isHidden: boolean;
  created: {
    first_name: string;
    last_name: string;
    email: string;
    role: string;
    _id: number;
  };
  link: GlobalLinkModel;
  images: ProductImageModel[];
  parameters: ProductParameterModel[];
  views: number;
};

export type ProductRequestModel = {
  title?: string;
  creator?: string;
  description?: string;
  stoneType?: string;
  productType?: string;
  category?: string;
  price?: number;
  caratWeight?: number;
  quantity?: number;
  discount?: number;
  mediaIDS?: number[];
  parameters?: ProductParameterRequestModel[];
};

export type ProductPriceRangeModel = {
  max: Nullable<number>;
  min: Nullable<number>;
};

export type ProductRangeModel = {
  min: number;
  max: number;
};

export type ProductTypeMediaModel = {
  type: MediaTypeKeys;
  guid: string; // "68664ed8-2a59-4b0f-9b29-a8f1bba02f88"
  createdAt: string; // "2007-03-11T13:38:52.000Z"
  updatedAt: string; // "2021-09-07T15:27:10.878Z"
  id: number;
  user_id: number;
  sort_index: number;
  position: number;
  size: number; // 3714156 in bytes
  arData?: MediaBlinkData;
  rapnetId: null;
  shopifyId: null;
  file?: GlobalImageDataModel;
  metaData: ObjectType;
};

export type ProductTypeTemplateModel = {
  description: string;
  name: string;
  id: number;
  user_id: number;
  position: number;
  medias: ProductTypeMediaModel[];
};

export type ProductTypeModel = {
  description: string;
  kind: "default" | "custom";
  name: ProductTypeValues;
  id: number;
  user_id: number;
  position: number;
  showInARFlow: boolean;
  templates: ProductTypeTemplateModel[];
};

export type ProductTypeOutput = {
  custom: ProductTypeModel[];
  default: ProductTypeModel[];
};

export type ProductVariantOptionValueModel = {
  createdAt: string;
  updatedAt: string;
  value: string;
  id: number;
  option_id: number;
};

export type ProductVariantOptionModel = {
  name: string;
  createdAt: string; // "2023-07-07T09:21:40.195Z"
  updatedAt: string; // "2023-07-07T09:21:40.195Z"
  id: number;
  order: number;
  product_id: ProductModel["_id"];
  option_values: ProductVariantOptionValueModel[];
};
