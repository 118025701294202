import { PostLoginPayloadType } from "src/api";

export type LoginDataType = PostLoginPayloadType & {
  shopifyToken?: string;
};

export type InvoiceType = {
  status: "paid" | "void" | "open" | "refunded" | "partially_refunded";
  amount: number;
  date: string;
  currency: string;
  invoice_pdf: string;
  id: string;
  number: string;
  status_url: string;
};

export enum MessageTypes {
  logout = "logout",
  closed = "closed",
  redirect = "redirect",
  subscriptionsSuccess = "subscriptionsSuccess",
}
