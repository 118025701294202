import React from "react";
import { observer } from "mobx-react-lite";
import { Button, clsx, SvgIcon } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { FreePlanBadge } from "src/external-ts/components";
import { subscriptionsData } from "../../../../subscriptions.constants";
import { YourPlanBadge } from "../your-plan-badge";

import { ReactComponent as MinusSVG } from "src/external-ts/assets/images/minus-grey.svg";

import contentStyles from "../../content.module.css";
import styles from "./free-offer.module.css";
import { translate } from "../pro-offer/pro-offer.utils";

export interface FreeOfferProps {
  onDowngrade: VoidFunction;
  className?: string;
  canDisplayAddons?: boolean;
}

export const FreeOffer: React.FC<FreeOfferProps> = observer(
  ({ onDowngrade, className = "", canDisplayAddons = true }: FreeOfferProps) => {
    const { subscriptionsStore, localeStore } = useStores();

    const {
      isFreeSubscription,
      isCustomSubscription,
      isCancellingSubscription,
      isProLifetimeSubscription,
    } = subscriptionsStore;

    return (
      <div className={clsx(styles.freeOfferItem, className)}>
        <div className={styles.content}>
          {isFreeSubscription && <YourPlanBadge />}
          <div className={styles.freeOfferHeader}>
            <FreePlanBadge split secondaryTextColor />
            <div className={styles.freeOfferPriceWrapper}>
              <span className={styles.freeOfferPrice}>$0/mo</span>
            </div>
            <Button
              className={styles.freeOfferDowngradePlan}
              appearance={isFreeSubscription ? "secondaryOutlined" : "primaryOutlined"}
              onClick={isFreeSubscription ? undefined : onDowngrade}
              disabled={
                isProLifetimeSubscription || isCustomSubscription || isCancellingSubscription
              }
            >
              {isProLifetimeSubscription
                ? "Free"
                : isFreeSubscription
                ? localeStore.t("settings.subscriptions.components.content.buttons.current")
                : localeStore.t("settings.subscriptions.components.content.buttons.downgrade")}
            </Button>
          </div>
          <div className={contentStyles.offerContent}>
            {canDisplayAddons && (
              <div className={contentStyles.featureOfferWrapper}>
                <div className={clsx(contentStyles.featureOfferAmount, styles.minusIconContainer)}>
                  <SvgIcon icon={MinusSVG} size={10} />
                </div>
                <div className={contentStyles.featureOfferAmount}>3GB</div>
              </div>
            )}
            <div className={contentStyles.featureOfferListWrapper}>
              {subscriptionsData.features.map(({ freePlanAmount }, index) => (
                <div key={index} className={contentStyles.feature}>
                  {freePlanAmount !== "-" ? (
                    translate(freePlanAmount)
                  ) : (
                    <SvgIcon className={styles.minusIcon} icon={MinusSVG} size={10} />
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default FreeOffer;
