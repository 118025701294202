import { Button } from "@gemlightbox/core-kit";
import Dialog, { dialogClasses } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getSettingsTab, SettingTabNames } from "src/containers/settings";
import { useStores } from "src/hooks";
import { ModalExtendedType } from "src/store";

import styles from "./cloud-storage-full-modal.module.css";

import bannerImg from "src/external-ts/assets/images/cloud-storage-full-caption.png";
import { getUserTagManagerInfoCallback } from "src/api";
import { pushDataLayerEvent } from "src/utils";
import { config } from "src/config/environment";

const StyledDialog = styled(Dialog)(() => ({
  zIndex: 10000,

  [`& .${dialogClasses.paper}`]: {
    maxWidth: "480px",
    width: "480px",
    borderRadius: "16px",
  },
  "& .MuiDialogTitle-root": {
    padding: 0,
  },
  "& .MuiDialogContent-root": {
    padding: "0 32px",
  },
}));

export type CloudStorageFullProps = ModalExtendedType<{ formattedMessage: string }>;

export const CloudStorageFullModal: React.FC<CloudStorageFullProps> = observer(
  ({ isOpen, setClose, options }) => {
    const navigate = useNavigate();
    const { localeStore, trashBinStore, modalsStore, userStore } = useStores();
    const { totalTrashAmount } = trashBinStore;

    const handleUpgrade = () => {
      setClose();
      if (modalsStore.modals.ProductAssignMediasSidebar) {
        modalsStore.close("ProductAssignMediasSidebar");
      }

      if (window.$platform.isCompact) {
        userStore.parentPostMessageRedirect(`${config.gemhubUrl}/profile/subscriptions`);
      } else {
        navigate(getSettingsTab(SettingTabNames.subscriptions)?.item.path as string);
      }
    };

    useEffect(() => {
      getUserTagManagerInfoCallback((response) => {
        pushDataLayerEvent({
          event: "run_out_of_storage",
          event_params: {
            user_id: response.user_id,
            source_platform: options.formattedMessage,
          },
        });
      });
      trashBinStore.loadTrashBinList();
    }, []);

    return (
      <StyledDialog open={isOpen} maxWidth="xs">
        <DialogTitle className={styles.dialogHeader}>
          <img src={bannerImg} className={styles.banner} alt="banner" />
        </DialogTitle>

        <DialogContent>
          <div className={styles.contentText}>
            {localeStore.t('common.cloud["storage-full-tip-title"]')}
          </div>
          <div className={styles.contentSubText}>
            {localeStore.t('common.cloud["storage-full-tip-subtitle"]')}
          </div>
        </DialogContent>

        <DialogActions
          sx={{
            flexDirection: "column",
            marginTop: "44px",
            padding: "0 32px 12px",
          }}
        >
          <Button className={styles.submitBtn} onClick={handleUpgrade}>
            {localeStore.t("common.cloud.upgrade")}
          </Button>
          <Button
            appearance="secondaryOutlined"
            className={styles.cancelBtn}
            onClick={() => {
              setClose();
              if (modalsStore.modals.ProductAssignMediasSidebar) {
                modalsStore.close("ProductAssignMediasSidebar");
              }
              if (totalTrashAmount > 0) {
                if (window.$platform.isCompact) {
                  userStore.parentPostMessageRedirect(`${config.gemhubUrl}/trash`);
                } else {
                  navigate("/trash-bin");
                }
              } else {
                if (window.$platform.isCompact) {
                  userStore.parentPostMessageRedirect(`${config.gemhubUrl}`);
                } else {
                  navigate("/media");
                }
              }
            }}
          >
            {localeStore.t('common.cloud["clear-space"]')}
          </Button>
        </DialogActions>
      </StyledDialog>
    );
  },
);
