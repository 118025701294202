import React, { useState, useRef, useMemo } from "react";
import { useFormikContext } from "formik";
import { observer } from "mobx-react-lite";
import {
  FormField,
  useDidUpdate,
  SelectOption,
  InputImperativeContent,
  clsx,
  TextEditorUtils,
  NotificationTextSecondary,
  Select,
  SvgIcon,
} from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { AttributeModel, AttributeType, LanguageType } from "src/models";
import {
  getAttributeDisplayName,
  getAttributePlaceholder,
  validateForRequired,
  validatePrice,
} from "src/utils";
import { numberNormalizer } from "src/common/helpers/common.helpers";
import { DescriptionContent } from "./description-content";
import { filledStyleAttributes } from "./attribute-field.constants";
import { getBottomMessage, getAttributeOptions } from "./attribute-field.utils";
import { ReactComponent as LanguageSVG } from "src/external-ts/assets/images/language-icon.svg";

import styles from "./attribute-field.module.css";
import { LocaleCodeTypes } from "src/store/locale/locale-generated.store.types";

export interface AttributeProps {
  attribute: AttributeModel;
  onDeleteAttributeOption: (attribute: AttributeModel) => void;
  onGenerateDescription: VoidFunction;
  onProductTypeOptionChange?: (option: SelectOption) => void;
  initialValue?: string;
  onClose?: VoidFunction;
}

export const AttributeField: React.FC<AttributeProps> = observer(
  ({
    attribute,
    onDeleteAttributeOption,
    onProductTypeOptionChange,
    onGenerateDescription,
    initialValue,
    onClose,
    ...rest
  }: AttributeProps) => {
    const { attributesStore, categoriesStore, notificationStore, localeStore, userStore } =
      useStores();

    const { id, name, type, required, values: attributeValues, suffix, prefix } = attribute;

    const { values } = useFormikContext<any>();
    const isDescription = name === "description";

    const hasValue = useMemo(() => {
      const val = values[name];

      if (isDescription) {
        if (Array.isArray(val)) {
          const length = TextEditorUtils.calculateLength(TextEditorUtils.normalizeEditorValue(val));
          return length > 0;
        }
      }

      return !!val;
    }, [values[name], isDescription]);

    const inputImperativeRef = useRef<InputImperativeContent>(null);

    const [blurCounter, setBlurCounter] = useState(0);
    const [isInDescriptionEdit, setIsInDescriptionEdit] = useState(hasValue);

    useDidUpdate(() => setIsInDescriptionEdit(hasValue), [hasValue]);

    const handleEnterManuallyClick = () => {
      setIsInDescriptionEdit(true);
      const elem = document.querySelector("[data-slate-editor]") as HTMLElement;

      // NOTE: https://stackoverflow.com/questions/2388164/set-focus-on-div-contenteditable-element
      setTimeout(() => {
        elem?.focus();
      }, 0);
    };

    const handleDeleteAttributeOption = (attribute: AttributeModel, option: SelectOption) => {
      const { value } = option;
      const data = {
        ...attribute,
        values: attribute.values?.filter((val) => val !== value) || [],
      };
      const requestData = attributesStore.getAttributeRequestPayload(data);

      if (requestData.values.length === 0) {
        notificationStore.open({
          title: localeStore.t(
            '["product-attributes"]["attribute-field"]["dropdown-warning-modal"].title',
          ),
          message: (
            <>
              <NotificationTextSecondary>
                {localeStore.t(
                  '["product-attributes"]["attribute-field"]["delete-modal"].description',
                )}
                <br />
                <br />
                {localeStore.t(
                  '["product-attributes"]["attribute-field"]["dropdown-warning-modal"].description.last',
                )}
              </NotificationTextSecondary>
            </>
          ),

          confirmText: localeStore.t(
            '["product-attributes"]["attribute-field"]["dropdown-warning-modal"]["ok-text"]',
          ),
          cancelText: "",
          onlyConfirm: true,
        });
      } else {
        notificationStore.open({
          title: localeStore.t('["product-attributes"]["attribute-field"]["delete-modal"].title'),
          message: localeStore.t(
            '["product-attributes"]["attribute-field"]["delete-modal"].description',
          ),

          confirmText: localeStore.t("common.buttons.confirm"),
          cancelText: localeStore.t("common.buttons.cancel"),
          icon: "exclamation",
          onOk: async () => {
            await attributesStore.updateAttribute(data.id, requestData);
            onDeleteAttributeOption(data);
          },
        });
      }
    };

    const handleCreateNewOption = (attribute: AttributeModel) => async (search: string) => {
      const trimmedNewAttributeValue = search.trim();

      const data = {
        ...attribute,
        values: attributeValues?.concat(trimmedNewAttributeValue) || [],
      };

      const requestData = attributesStore.getAttributeRequestPayload(data);
      await attributesStore.updateAttribute(data.id, requestData);

      handleDropDownChange({
        value: trimmedNewAttributeValue,
        label: trimmedNewAttributeValue,
      });
    };

    const handleBlur = () => {
      const shouldRenderConfetti =
        blurCounter < 1 && isFilledStyleAttribute && values[name] !== initialValue;

      if (shouldRenderConfetti) {
        inputImperativeRef.current?.confettiBoolean.setTruthy();
        setBlurCounter(blurCounter + 1);
      }

      if (isDescription && !hasValue) {
        setIsInDescriptionEdit(false);
      }
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
      e.stopPropagation();
      if (e.key !== "Enter" || isDescription) return;
      handleBlur();
    };

    const handleDropDownChange = (option: { value: string; label: string }) => {
      renderConfetti(option?.value);

      if (isProductType) onProductTypeOptionChange?.(option);
    };

    const renderConfetti = (currentValue?: string) => {
      const shouldRenderConfetti =
        blurCounter < 1 && isFilledStyleAttribute && currentValue && currentValue !== initialValue;

      if (shouldRenderConfetti) {
        inputImperativeRef.current?.confettiBoolean.setTruthy();
        setBlurCounter(blurCounter + 1);
      }
    };

    const isFilledStyleAttribute = filledStyleAttributes.some(
      (styledAttributeName) => attribute.name === styledAttributeName,
    );

    let label = getAttributeDisplayName(attribute);
    const isPrice = name === "price";
    const isProductType = name === "productType";
    const validate = required ? validateForRequired : undefined;

    const localeKey = label.replace(" ", "").toLowerCase();
    if (["title", "price", "producttype", "description", "quantity"].includes(localeKey)) {
      label = localeStore.t(
        `products["products-list"]["products-table"]["products-table-header"].${localeKey}` as LocaleCodeTypes,
        localeKey,
      );
    }

    const disabled = name === "title" && userStore.isCTFSub;

    return (
      <React.Fragment key={id}>
        {(type === AttributeType.text || type === AttributeType.number) && !isDescription && (
          <FormField
            type="text"
            appearance="primaryV2"
            labelMessage={
              attribute.name === "title"
                ? localeStore.t('["product-attributes"]["attribute-field"]["label-message"]')
                : undefined
            }
            messageAlign="left"
            bottomMessageAlign="opposite-of-error"
            inputWrapperClassName={clsx({ [styles.descriptionWrapper]: isDescription })}
            name={`['${name}']`}
            label={label}
            bottomMessage={getBottomMessage(name)}
            placeholder={getAttributePlaceholder(attribute)}
            prefix={isPrice ? suffix : prefix}
            affixClassName={styles.affix}
            suffix={isPrice ? undefined : suffix}
            forwardImperativeRef={inputImperativeRef}
            {...(type === "number" && { normalize: numberNormalizer })}
            validate={isPrice ? validatePrice : validate}
            required={required}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            enableFilledStyles={isFilledStyleAttribute}
            disabled={disabled}
            {...rest}
          />
        )}
        {isDescription && (
          <FormField
            type="text-editor"
            name={`['${name}']`}
            label={localeStore.t(
              'products["products-list"]["products-table"]["products-table-header"].description',
            )}
            hintSelectedText={localeStore.t(
              '["product-attributes"]["attribute-field"].description["hint-selected-text"]',
            )}
            hintUnselectedText={localeStore.t(
              '["product-attributes"]["attribute-field"].description["hint-unselected-text"]',
            )}
            linkInputTitle={localeStore.t(
              '["product-attributes"]["attribute-field"].description["link-input-title"]',
            )}
            linkInputLabel={localeStore.t(
              '["product-attributes"]["attribute-field"].description["link-input-label"]',
            )}
            linkInputConfirmText={localeStore.t(
              '["product-attributes"]["attribute-field"].description["link-input-confirm-text"]',
            )}
            withLink={false}
            required={required}
            disabled={!isInDescriptionEdit}
            onBlur={handleBlur}
            {...rest}
            containerClassName={styles.descriptionContainer}
          >
            <DescriptionContent
              onUpgradeClick={onClose}
              onCreateAIDescription={onGenerateDescription}
              onEnterManuallyClick={handleEnterManuallyClick}
              hasValue={isInDescriptionEdit || hasValue}
            />
            <div className={styles.selectAiLanguageWrapper}>
              <SvgIcon icon={LanguageSVG} size={16} />
              <Select
                className={styles.selectAiLanguage}
                inputWrapperClassName={styles.selectAiLanguageInput}
                dropdownClassName={styles.selectAiLanguageDropdown}
                data-name="ai-languag-dropdown"
                data-hj-allow
                appearance="primaryV2"
                labelAlign="left"
                label={localeStore.t('settings.profile["language-block"]["ai-language"]') + ":"}
                selectedOptionsKeys={userStore.aiLanguage}
                options={Object.values(LanguageType).map((item) => ({
                  label: localeStore.t(
                    `settings.profile["language-block"]["language-labels"]["${item.toLowerCase()}"]` as LocaleCodeTypes,
                  ),
                  value: item,
                  indelible: true,
                }))}
                onChange={(e) => {
                  userStore.setAiLanguage(e?.value);
                }}
                disableClearing
              />
            </div>
          </FormField>
        )}
        {(type === AttributeType.select || type === AttributeType.multiselect) && (
          <FormField
            type="select"
            appearance="primaryV2"
            bottomMessageAlign="opposite-of-error"
            messageAlign="left"
            name={`['${name}']`}
            placeholder={getAttributePlaceholder(attribute, true)}
            label={label}
            bottomMessage={getBottomMessage(name)}
            options={getAttributeOptions(attribute, categoriesStore.defaultCategories)}
            onOptionDelete={(option) => handleDeleteAttributeOption(attribute, option)}
            onCreateNew={handleCreateNewOption(attribute)}
            validate={validate}
            required={required}
            isMulti={type === AttributeType.multiselect}
            forwardImperativeRef={inputImperativeRef}
            onChange={handleDropDownChange}
            enableFilledStyles={isFilledStyleAttribute}
            disabled={disabled}
            capitalizeOptions={isProductType}
            disableConcatPrevSelectedOpts
            trimCreatedOption
            disableClearing
            isTextEditable
            canCreate
            {...rest}
          />
        )}
      </React.Fragment>
    );
  },
);
