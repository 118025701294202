import React, { useState, useRef, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import {
  Button,
  SvgIcon,
  GooLoader,
  useDidMount,
  clsx,
  Webgl2New,
  imageSourceToCanvas,
  loadImage,
  loadVideo,
  useBoolean,
  useThrottle,
  useDidUpdate,
  useDidUnmount,
} from "@gemlightbox/core-kit";
import { v7 as uniqId } from "uuid";
import Snackbar from "@mui/material/Snackbar";
import { changeDpiDataUrl } from "changedpi";

import { MEDIA_PAGE } from "src/constants";
import {
  isFirstEnterGemCamLocalKey,
  useIsLinkEntrance,
  useStores,
  usePageViewEvent,
  showGemCamLocalKey,
} from "src/hooks";
import { CameraControls } from "./camera-controls";
import { CameraTroubleshooting } from "./camera-troubleshooting";
import { Stopwatch } from "./stopwatch";
import { cameraStore } from "./camera.store";
import { fastVideoLimit, slowVideoLimit } from "./camera.constants";
import { CaptureModeType, CameraMediaType, FilterMode } from "./camera.types";
import { BluetoothPermModal } from "./bluetooth-permission-modal";
import { WinBluetoothPermModal, WinBluetoothPermTipsModal } from "./bluetooth-permission-modal-win";
import { BrowserUpgradeModal } from "./browser-upgrade-modal";
import { SaveProgress } from "src/components/SaveProgress";
import { TutorialsModal } from "./tutorials-modal";
import { CameraResDetection } from "./camera-res-detection";
import { ResDetectionModal } from "./res-detection-modal";
import { deviceListModalStore } from "./device-list-modal";

import { ReactComponent as CloseSVG } from "src/external-ts/assets/images/cross-v2-grey.svg";
import { ReactComponent as TurnLeftSVG } from "src/external-ts/assets/images/camera/turn-left-icon.svg";
import { ReactComponent as TurnRightSVG } from "src/external-ts/assets/images/camera/turn-right-icon.svg";
import { ReactComponent as TabPhotoSVG } from "src/external-ts/assets/images/camera/tab-photo-btn.svg";
import { ReactComponent as TabVideoSVG } from "src/external-ts/assets/images/camera/tab-video-btn.svg";
import { ReactComponent as FocusGuideBoxSVG } from "src/external-ts/assets/images/camera/focus-guide-box.svg";
import { ReactComponent as SnackbarFocusSVG } from "src/external-ts/assets/images/camera/snackbar-focus-icon.svg";
import { ReactComponent as FocusIconSVG } from "src/external-ts/assets/images/camera/focus-icon.svg";
import styles from "./camera.page.module.css";
import { GlobalInstance } from "src/utils/global";
import { pushDataLayerEvent } from "src/utils";

let startRecordingTime = 0;
let cancelVideoPreview = false;
let focusDelayTimer: NodeJS.Timeout | null = null;
const snackbarTriggerTimeKey = "__snackbarTriggerTimeKey__";

export const CameraPage: React.FC = observer(() => {
  usePageViewEvent();
  useIsLinkEntrance();
  const navigate = useNavigate();
  const [mediaList, setMediaList] = useState<CameraMediaType[]>([]);
  const [captureMode, setCaptureMode] = useState<CaptureModeType>(
    cameraStore.captureModeStorageData?.captureMode ?? "photo",
  );
  const isOpenTutorial = useBoolean(localStorage.getItem(isFirstEnterGemCamLocalKey) === "true");
  const isVideoRecordStoped = useBoolean(false);
  const bluetoothListBool = useBoolean(false);
  const bluetoothPermBool = useBoolean(false);
  const winBluetoothPermBool = useBoolean(false);
  const winBluetoothPermTipsBool = useBoolean(false);
  const browserUpgradeVisibleBool = useBoolean(false);
  const snackbarTipVisibleBool = useBoolean(false);
  const focusActiveBool = useBoolean(false);
  const recordTimer = useRef<number | undefined>();
  const videoCaptureThrottle = useThrottle(1000);
  const resDetectionBool = useBoolean(false);

  const canvasWrapperRef = useRef<HTMLDivElement>(null);

  const { userStore, modalsStore, notificationStore, localeStore, mediaStore, appCallbackStore } =
    useStores();
  const {
    videoRef,
    connectionStatus,
    isRecording,
    isBurstMode,
    videoSpeed,
    videoAngle,
    bluetoothDeviceSelected,
    focusMode,
    resDetPctVal,
    dpi,
    openEclipse,
  } = cameraStore;

  const cleanup = async () => {
    if (mediaStore.savedStatus === 1 || mediaStore.savedStatus === 3) {
      mediaStore.setSavedStatus(0);
      mediaStore.setPreUploadStatus(0);
      mediaStore.setPreUploadFiles([]);
      mediaStore.uploadRequest?.abort();
      mediaStore.saveRequest?.abort();
      return;
    }
    GlobalInstance.isManualStopRecord = true;
    cameraStore.handleStopRecord().catch((error) => {
      console.error("Error stopping the recording:", error);
    });
    closePreviewSidebar();
  };

  const handleGoBack = (mediaId?: Nullable<number>) => {
    userStore.parentPostMessageClosed({ mediaId });
    navigate(-1);
  };

  const handleClose = () => {
    if (isRecording || mediaStore.savedStatus === 1 || mediaStore.savedStatus === 3) {
      notificationStore.open({
        title: localeStore.t('camera["close-gemcam-title"]'),
        message: isRecording
          ? localeStore.t('camera["close-gemcam-msg"]')
          : localeStore.t('camera["close-gemcam-msg-confirm"]'),
        confirmText: localeStore.t('camera["close-gemcam-confirm"]'),
        cancelText: isRecording
          ? localeStore.t('camera["close-gemcam-cancel"]')
          : localeStore.t('camera["close-gemcam-cancel-confirm"]'),
        onOk: async () => {
          handleGoBack();
        },
      });
    } else {
      handleGoBack();
    }
  };

  let videoLimit = videoSpeed === 0 ? fastVideoLimit : slowVideoLimit;

  if (videoAngle !== 360) {
    // The rotation duration is calculated based on 4 times the angle.
    videoLimit = videoLimit * ((videoAngle * 4) / 360);
  }

  const uploadVideoNew = async (blob: Blob) => {
    isVideoRecordStoped.setTruthy();
    cameraStore.setFocusMode(focusMode, false);

    const video = await loadVideo(URL.createObjectURL(blob), true);
    cameraStore.setGemcamPreviewVideo({ element: video, previewSrc: "", processd: "original" });

    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    const videoLength = Date.now() - startRecordingTime;
    const step = parseInt(String(videoLength / 4));
    const timestamps = new Array(4).fill(0).map((_, index) => {
      return Number(((step * index) / 1000).toFixed(2));
    });
    const list: CameraMediaType[] = [...mediaList];
    if (videoAngle !== 360) {
      // Remove the third picture other than 360 degrees
      timestamps.splice(2, 1);
    }
    video.addEventListener("loadeddata", () => {
      let isAppUsable = window.$platform.isApp;
      if (isAppUsable && window.$platform.os == "Win") {
        if (window.$platform.AppInfo) {
          isAppUsable =
            parseInt(window.$platform.AppInfo.appBuildVersion.replace(/\./g, "")) >= 1030;
        } else {
          isAppUsable = false;
        }
      }

      if (isAppUsable) {
        if (isBurstMode) {
          const fileReader = new FileReader();
          fileReader.onload = async function (event) {
            const base64data = event.target?.result + "";
            appCallbackStore.videoScreenCapture(
              base64data.replace("data:video/mp4;base64,", ""),
              (data: any) => {
                const img1 = new Image();
                img1.src = dpi
                  ? changeDpiDataUrl("data:image/png;base64," + data?.pic1, dpi)
                  : "data:image/png;base64," + data?.pic1;

                mediaStore.setSavedCancelImg(img1.src);
                list.push({
                  element: video,
                  blobData: blob,
                  previewSrc: img1.src,
                  uuId: uniqId(),
                  processd: "original",
                });
                list.push({
                  element: img1,
                  previewSrc: img1.src,
                  uuId: uniqId(),
                  processd: "original",
                });

                const img2 = new Image();
                img2.src = dpi
                  ? changeDpiDataUrl("data:image/png;base64," + data?.pic2, dpi)
                  : "data:image/png;base64," + data?.pic2;
                list.push({
                  element: img2,
                  previewSrc: img2.src,
                  uuId: uniqId(),
                  processd: "original",
                });

                if (videoAngle === 360) {
                  const img3 = new Image();
                  img3.src = dpi
                    ? changeDpiDataUrl("data:image/png;base64," + data?.pic3, dpi)
                    : "data:image/png;base64," + data?.pic3;
                  list.push({
                    element: img3,
                    previewSrc: img3.src,
                    uuId: uniqId(),
                    processd: "original",
                  });
                }

                const img4 = new Image();
                img4.src = dpi
                  ? changeDpiDataUrl("data:image/png;base64," + data?.pic4, dpi)
                  : "data:image/png;base64," + data?.pic4;
                list.push({
                  element: img4,
                  previewSrc: img4.src,
                  uuId: uniqId(),
                  processd: "original",
                });

                if (cancelVideoPreview) {
                  return;
                }

                const mediaPromises = list.map((media) => {
                  return new Promise<CameraMediaType>((resolve, reject) => {
                    if (media.element instanceof HTMLImageElement) {
                      if (media.element.complete) {
                        resolve(media);
                      } else {
                        media.element.onload = () => resolve(media);
                        media.element.onerror = (err) => reject(err);
                      }
                    } else if (media.element instanceof HTMLVideoElement) {
                      if (media.element.readyState >= 3) {
                        resolve(media);
                      } else {
                        media.element.onloadeddata = () => resolve(media);
                        media.element.onerror = (err) => reject(err);
                      }
                    } else {
                      reject(new Error("Unsupported media type"));
                    }
                  });
                });

                Promise.all(mediaPromises)
                  .then((mediaList) => {
                    setMediaList(() => mediaList);
                    openPreviewSidebar(mediaList, false);
                  })
                  .catch((error) => {
                    console.error("Failed to load one or more images", error);
                  });
              },
              (msg) => {
                console.error(msg);
              },
            );
          };
          fileReader.readAsDataURL(blob);
        } else {
          video.addEventListener(
            "timeupdate",
            async () => {
              const ctx = canvas.getContext("2d");
              const img = await delayCanvasToImg(video, canvas, ctx);
              list.push({
                element: video,
                blobData: blob,
                previewSrc: img.src,
                uuId: uniqId(),
                processd: "original",
              });
              mediaStore.setSavedCancelImg(img.src);
              if (cancelVideoPreview) {
                return;
              }
              setMediaList(() => list);
              openPreviewSidebar(list, false);
            },
            { once: true },
          );
          video.currentTime = 0.1;
        }
      } else {
        listenSeeked(video, canvas, list, timestamps, { isSetVideo: false });
      }
    });

    video.load();
    video.play();

    if (canvasWrapperRef.current) {
      canvasWrapperRef.current.style.transform = "";
    }
  };

  const listenSeeked = (
    video: HTMLVideoElement,
    canvas: HTMLCanvasElement,
    list: CameraMediaType[],
    timestamps: number[],
    extra: { isSetVideo: boolean },
  ) => {
    video.addEventListener(
      "timeupdate",
      async () => {
        const ctx = canvas.getContext("2d");
        const img = await delayCanvasToImg(video, canvas, ctx);

        if (!extra.isSetVideo) {
          list.push({ element: video, previewSrc: img.src, uuId: uniqId(), processd: "original" });
          extra.isSetVideo = true;
        }

        if (isBurstMode) {
          list.push({ element: img, previewSrc: img.src, uuId: uniqId(), processd: "original" });

          if (timestamps.length < 1) {
            if (cancelVideoPreview) {
              return;
            }
            setMediaList(() => list);
            openPreviewSidebar(list, false);
          } else {
            listenSeeked(video, canvas, list, timestamps, extra);
          }
        } else {
          if (cancelVideoPreview) {
            return;
          }
          setMediaList(() => list);
          openPreviewSidebar(list, false);
        }
        mediaStore.setSavedCancelImg(img.src);
      },
      { once: true },
    );
    video.currentTime = (timestamps.shift() ?? 0) || 0.1;
  };

  const delayCanvasToImg = (
    video: HTMLVideoElement,
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D | null,
  ): Promise<HTMLImageElement> => {
    return new Promise((resolve) => {
      ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);
      setTimeout(() => {
        const img = new Image();
        if (dpi) {
          img.src = changeDpiDataUrl(canvas.toDataURL("image/jpeg"), dpi);
        } else {
          img.src = canvas.toDataURL("image/jpeg");
        }
        resolve(img);
      }, 500);
    });
  };

  const handleCaptureVideoClick = () => {
    cameraStore.logoWatermarkDisplay("none");
    pushDataLayerEvent({ event: "click:camera:capture:video" });
    videoCaptureThrottle.throttle(async () => {
      if (isRecording) {
        pushDataLayerEvent({
          event: "gemhub:camera:capture:video:stopped",
          event_params: { reason: "manual" },
        });
        clearTimeout(recordTimer.current);
        GlobalInstance.isManualStopRecord = true;
        cameraStore.logoWatermarkDisplay("initial");
        cameraStore
          .handleStopRecord()
          .then(uploadVideoNew)
          .catch((error) => {
            console.error("Error stopping the recording:", error);
          });
        openPreviewSidebar([], true);
        return;
      }

      isVideoRecordStoped.setFalsy();
      await cameraStore.setFocusMode("manual", false);
      if (cameraStore.bluetoothDeviceSelected) {
        cameraStore
          .gemLightBoxStartRotating()
          .then(() => {
            if (!GlobalInstance.isManualStopRecord) {
              cameraStore.logoWatermarkDisplay("initial");
              pushDataLayerEvent({
                event: "gemhub:camera:capture:video:stopped",
                event_params: { reason: "timed" },
              });
              clearTimeout(recordTimer.current);
              cameraStore
                .handleStopRecord()
                .then(uploadVideoNew)
                .catch((error) => {
                  console.error("Error stopping the recording:", error);
                });
              openPreviewSidebar([], true);
            }
          })
          .catch((error) => {
            console.error("Error bluetooth turntable for connection:", error);
          });
      }
      await cameraStore.handleStartRecord();
      GlobalInstance.isManualStopRecord = false;
      startRecordingTime = Date.now();

      recordTimer.current = window.setTimeout(() => {
        if (!GlobalInstance.isManualStopRecord) {
          cameraStore.logoWatermarkDisplay("initial");
          pushDataLayerEvent({
            event: "gemhub:camera:capture:video:stopped",
            event_params: { reason: "system_abort" },
          });
          GlobalInstance.isManualStopRecord = true;
          cameraStore
            .handleStopRecord()
            .then(uploadVideoNew)
            .catch((error) => {
              console.error("Error stopping the recording:", error);
            });
          openPreviewSidebar([], true);
        }
      }, videoLimit + 1000); // Delay 1 second auto stop
    });
  };

  const handleCaptureImage = async () => {
    pushDataLayerEvent({ event: "click:camera:capture:photo" });
    cameraStore.logoWatermarkDisplay("none");
    const webgl2 = Webgl2New.Webgl2;

    const prevViewport = webgl2.camera.projection;
    webgl2.camera.save();

    const canvasSize = cameraStore.renderer.canvasSize;

    // Setup block
    webgl2.setViewport(canvasSize);
    webgl2.camera.copy(cameraStore.renderer.camera as any);

    cameraStore.renderer.renderComponents();
    const canvas = imageSourceToCanvas(webgl2.canvas);

    webgl2.setViewport(prevViewport);
    webgl2.camera.restore();

    const base64 = dpi
      ? changeDpiDataUrl(canvas.toDataURL("image/jpeg"), dpi)
      : canvas.toDataURL("image/jpeg");
    const image = await loadImage(base64, true);

    setMediaList((list) => {
      const ret = [
        ...list,
        {
          element: image,
          previewSrc: image.src,
          uuId: uniqId(),
          processd: "original",
        } as CameraMediaType,
      ];
      openPreviewSidebar(ret, false);
      return ret;
    });
    cameraStore.logoWatermarkDisplay("initial");
  };

  const handleOnceFocus = async () => {
    if (focusDelayTimer || isRecording) {
      return;
    }

    if (!openEclipse && cameraStore.isFocusViewCorrection)
      cameraStore?.mainImageComponent?.isAutoWhiteBalance(true);
    focusActiveBool.setTruthy();
    if (cameraStore.focusMode === "continuous") {
      await cameraStore.setFocusMode("manual", false);
      focusDelayTimer = setTimeout(() => {
        focusDelayTimer && clearTimeout(focusDelayTimer);
        focusDelayTimer = null;
        focusActiveBool.setFalsy();
      }, 2500);
      setTimeout(async () => {
        await cameraStore.setFocusMode("continuous", false);
      }, 100);
    } else {
      await cameraStore.setFocusMode("continuous", false);
      focusDelayTimer = setTimeout(async () => {
        await cameraStore.setFocusMode("manual", false);
        focusDelayTimer && clearTimeout(focusDelayTimer);
        focusDelayTimer = null;
        focusActiveBool.setFalsy();
      }, 2400);
    }
  };

  const openPreviewSidebar = (list: CameraMediaType[], loading: boolean) => {
    cancelVideoPreview = false;
    modalsStore.open("CameraPreviewSidebar", {
      mediaList: list,
      onClose: handleClosePreviewSidebar,
      loading,
      captureMode,
    });
    mediaStore.setSidebarOpened(true);
  };

  const closePreviewSidebar = () => {
    modalsStore.close("CameraPreviewSidebar");
  };

  const handleClosePreviewSidebar = () => {
    mediaStore.setSidebarOpened(false);
    cancelVideoPreview = true;
    setMediaList([]);
  };

  const handleClickArrow = (direction: number) => async () => {
    if (isRecording) return;
    await cameraStore.gemLightBoxStartTurning(direction);
  };

  const handleCaptureModeChange = (value: CaptureModeType) => () => {
    //do nothing if value is the same
    if (value === captureMode || isRecording) return;

    const resolution =
      value === "video" ? cameraStore.resolutionVideo : cameraStore.resolutionPhoto;
    cameraStore.changeResolution(resolution, value);
    cameraStore.setResolutionMode("regular");
    if (value === "video") {
      cameraStore.setGemAIActive(isBurstMode);
    } else {
      cameraStore.setGemAIActive(true);
    }

    setCaptureMode(value);
  };

  const handleTutorialClose = () => {
    isOpenTutorial.setFalsy();
  };

  const handleTutorialToggle = () => {
    isOpenTutorial.trigger();
  };

  const renderTurntableBox = () => {
    return (
      bluetoothDeviceSelected && (
        <div className={styles.arrowButtonsContainer}>
          <Button appearance="primaryGhost" onClick={handleClickArrow(0)}>
            <SvgIcon icon={TurnLeftSVG} size={48} />
          </Button>
          <Button appearance="primaryGhost" onClick={handleClickArrow(1)}>
            <SvgIcon icon={TurnRightSVG} size={48} />
          </Button>
        </div>
      )
    );
  };

  useDidMount(async () => {
    if (window.$platform.isApp) {
      localStorage.setItem(showGemCamLocalKey, "true");
      userStore.updateAttemptedGemCamSetup();
      cameraStore.abortStopScanBuletoothDevice();
      window.removeEventListener("beforeunload", cameraStore.stopScanBuletoothDevice);
      window.addEventListener("beforeunload", cameraStore.stopScanBuletoothDevice);
    }

    if (!canvasWrapperRef.current) return;
    await cameraStore.mount(canvasWrapperRef.current, browserUpgradeVisibleBool.setTruthy);
    return () => cameraStore.unmount();
  });

  useDidUnmount(() => {
    cameraStore.stopScanBuletoothDevice(30);
  });

  useEffect(() => {
    return () => {
      cleanup();
      cameraStore.unmount();
    };
  }, []);

  useDidUpdate(() => {
    if (connectionStatus === "connected") {
      const snackbarTriggerTime = Number(localStorage.getItem(snackbarTriggerTimeKey) ?? "0");
      const now = new Date().getTime();
      const oneDay = 24 * 60 * 60 * 1000;
      const needShow = now - snackbarTriggerTime >= oneDay;
      if (needShow) {
        snackbarTipVisibleBool.setTruthy();
        localStorage.setItem(snackbarTriggerTimeKey, String(now));
      }

      if (!bluetoothDeviceSelected) {
        if (deviceListModalStore.deviceSelectedStorage) {
          deviceListModalStore.setDeviceSelected(deviceListModalStore.deviceSelectedStorage);
        } else if (cameraStore.filterValue === FilterMode.GemCamOnly) {
          deviceListModalStore.setDeviceSelectedByvalue(cameraStore.filterValue);
        } else {
          cameraStore.bluetoothConnectTurnTable().then((status) => {
            if (status < 5) {
              bluetoothPermBool.setTruthy();
            } else {
              modalsStore.open("DeviceListModal", {
                bluetoothAutoConnect: true,
                bluetoothPermBool,
                onClose: () => handleClose(),
              });
            }
          });
        }
      }
    } else {
      modalsStore.close("DeviceListModal");
    }
  }, [connectionStatus]);

  const inZoom = cameraStore.cameraScale > 1;

  return (
    <div className={styles.cameraPageContainer}>
      <Button className={styles.closeButton} appearance="secondaryOutlined" onClick={handleClose}>
        <SvgIcon icon={CloseSVG} />
      </Button>

      {connectionStatus === "pending" && (
        <div className={styles.loaderContainer}>
          <Button
            className={styles.closeButton}
            appearance="secondaryOutlined"
            onClick={handleClose}
          >
            <SvgIcon icon={CloseSVG} />
          </Button>
          <GooLoader />
        </div>
      )}

      {connectionStatus === "notConnected" && <CameraTroubleshooting />}

      <CameraResDetection
        isVisible={connectionStatus === "testFrameRateing"}
        progress={resDetPctVal}
      />

      <div
        className={clsx(styles.cameraWrapper, {
          [styles.cursorMove]: inZoom,
        })}
      >
        <video className={styles.cameraVideo} ref={videoRef} autoPlay playsInline muted />

        <div ref={canvasWrapperRef} className={styles.canvasContainer} onClick={handleOnceFocus}>
          <div className={styles.focusGuideBox}>
            <FocusGuideBoxSVG />
            <div
              className={clsx(styles.focusIcon, {
                [styles.active]: focusActiveBool.value,
              })}
            >
              <SvgIcon
                icon={FocusIconSVG}
                size={focusActiveBool.value ? 80 : 140}
                className={styles.focusIconSvg}
              ></SvgIcon>
            </div>
          </div>
        </div>
        <div className={styles.captureBottomToolbar}>
          <div className={styles.captureModeContainer}>
            <div className={styles.captureModeButton} onClick={handleCaptureModeChange("photo")}>
              <SvgIcon icon={TabPhotoSVG} size={24} />
            </div>
            <div className={styles.captureModeButton} onClick={handleCaptureModeChange("video")}>
              <SvgIcon icon={TabVideoSVG} size={24} />
            </div>
            <div
              className={clsx(styles.captureModeSlider, {
                [styles.photoActive]: captureMode === "photo",
                [styles.videoActive]: captureMode === "video",
              })}
            >
              <SvgIcon icon={captureMode === "photo" ? TabPhotoSVG : TabVideoSVG} size={24} />
            </div>
          </div>

          {captureMode === "photo" && (
            <div dir="ltr" className={styles.captureButtonBorder}>
              <div className={styles.captureButton} onClick={handleCaptureImage} />
              {renderTurntableBox()}
            </div>
          )}

          {captureMode === "video" && (
            <div dir="ltr" className={styles.videoCaptureBtn}>
              <Stopwatch
                key={videoLimit}
                videoLimit={videoLimit}
                stoped={isVideoRecordStoped.value}
                clickFn={handleCaptureVideoClick}
              ></Stopwatch>
              {renderTurntableBox()}
            </div>
          )}
        </div>
      </div>

      <CameraControls
        tutorialToggle={handleTutorialToggle}
        captureMode={captureMode}
        bluetoothListBool={bluetoothListBool}
        bluetoothPermBool={bluetoothPermBool}
        winBluetoothPermBool={winBluetoothPermBool}
        resDetectionBool={resDetectionBool}
      />

      <ResDetectionModal resDetectionBool={resDetectionBool}></ResDetectionModal>

      <BluetoothPermModal bluetoothPermBool={bluetoothPermBool}></BluetoothPermModal>

      <WinBluetoothPermModal
        bluetoothPermBool={winBluetoothPermBool}
        bluetoothPermTipsBool={winBluetoothPermTipsBool}
      ></WinBluetoothPermModal>

      <WinBluetoothPermTipsModal
        bluetoothPermBool={winBluetoothPermTipsBool}
      ></WinBluetoothPermTipsModal>

      <BrowserUpgradeModal visible={browserUpgradeVisibleBool}></BrowserUpgradeModal>

      <SaveProgress />

      <TutorialsModal
        isOpenTutorial={isOpenTutorial}
        onClose={handleTutorialClose}
      ></TutorialsModal>

      <Snackbar
        open={snackbarTipVisibleBool.value}
        onClose={snackbarTipVisibleBool.setFalsy}
        autoHideDuration={3500}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        key="tips"
      >
        <div className={styles.snackbarBox}>
          <SvgIcon icon={SnackbarFocusSVG} size={20}></SvgIcon>
          <span style={{ paddingLeft: "8px" }}>{localeStore.t('camera["grid-frame-tips"]')}</span>
        </div>
      </Snackbar>
    </div>
  );
});
