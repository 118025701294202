import { useStores } from "src/hooks";
import { SvgIcon } from "@gemlightbox/core-kit";
import { proPlanIdV2 } from "../../../../subscriptions.constants";
import { ReactComponent as RemoveSubaccountSVG } from "src/external-ts/assets/images/subscription/billing/remove-subaccount-icon.svg";
import styles from "./pro-offer.module.css";

const { subscriptionsStore, localeStore, subaccountsStore, notificationStore } = useStores();
export const handleSubaccountsCheckNumber = (
  extraCount: number,
  onOk: VoidFunction,
  onOpenBilling: VoidFunction,
) => {
  const matchPlan =
    subscriptionsStore.currentPlan?.plan_id === proPlanIdV2
      ? subscriptionsStore.proPlanV2
      : subscriptionsStore.proPlan;

  const planSubaccountsUnit = Number(matchPlan?.metadata?.subaccounts || 0);
  if (subaccountsStore.confirmedSubaccounts.length > extraCount + planSubaccountsUnit) {
    notificationStore.open({
      contentClassName: styles.subaccountContent,
      title: (
        <span className={styles.subaccountTitle}>
          {localeStore.t(
            'settings.subscriptions.components["billing-modal"].components["billing-downgrade"]["sub-account"]',
          )}
        </span>
      ),
      icon: <SvgIcon icon={RemoveSubaccountSVG} size={[84, 68]} />,
      confirmAppearance: "primary",
      confirmText: localeStore.t(
        'settings.subscriptions.components["billing-modal"].components["billing-downgrade"]["go-subaccounts"]',
      ),
      cancelText: "",
      onlyConfirm: true,
      onOk: onOk,
    });
  } else {
    onOpenBilling();
  }
};

export const translate = (val: string) => {
  if (val === "3 catalogs")
    return localeStore.t(
      'settings.subscriptions.components.content["offer-features"]["three-catalogs"]',
    );

  if (val === "10/month")
    return localeStore.t(
      'settings.subscriptions.components.content["offer-features"]["ten-month"]',
    );

  if (val === "Unlimited")
    return localeStore.t('settings.subscriptions.components.content["offer-features"].unlimited');

  return val;
};
