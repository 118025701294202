import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { observer } from "mobx-react-lite";
import {
  Loader,
  useBoolean,
  useDidMount,
  useDidUpdate,
  useMediaBreakpoints,
  useQuery,
  queryStringify,
  clsx,
  capitalize,
  NotificationTextSecondary,
  NotificationText,
  NotificationBlock,
  SvgIcon,
  Button,
} from "@gemlightbox/core-kit";

import { DefaultRedirect } from "src/components/default-redirect";
import { getShopifyStoreURL } from "src/common/helpers/integrations.helpers";
import { getUserTagManagerInfoCallback, putSubscriptionAdditional } from "src/api";
import { pushDataLayerEvent, pushUserAttributeDataLayerEvent } from "src/utils";
import { useStores } from "src/hooks";
import { toggleShopifySelectStoreModal } from "src/redux/reducers/shopify";
import ShopifySubscriptionInProgress from "./shopify-subscription-in-progress/shopify-subscription-in-progress";
import {
  CurrentPlanOverview,
  Content,
  RenewPanel,
  SubscriptionCancelSurvey1st,
  SubscriptionCancelSurvey2nd,
  SubscriptionCancelSurvey1stForm,
  SubscriptionCancelSurvey2ndForm,
  BillingExtraData,
} from "./components";
import {
  additionalSubGbAddonId,
  additionalSubGbPriceMap,
  oppositeSubscriptionsMap,
  proPlanId,
  proSubscriptionsTypePriceMap,
} from "./subscriptions.constants";
import {
  HandleChangeShopifySubscription,
  SupportedSubscriptionInterval,
} from "./subscriptions.types";
import MobileTabHeader from "../MobileTabHeader";

import { ReactComponent as KeyIcon } from "../../../external-ts/assets/images/key2.svg";
import { ReactComponent as SuccessSVG } from "src/external-ts/assets/images/subscription/success-prompt.svg";
import globalStyles from "@gemlightbox/core-kit/dist/assets/styles/global.module.css";
import styles from "./subscriptions.module.css";
import { MessageTypes } from "src/store/user/user.store.types";

export const Subscriptions: React.FC = observer(() => {
  const dispatch = useDispatch();

  const {
    query: { payment_in_progress, cancel_in_progress },
    setQueryParams,
  } = useQuery();

  const {
    modalsStore,
    userStore,
    hubspotStore,
    shopifyStore,
    subscriptionsStore,
    subaccountsStore,
    notificationStore,
    localeStore,
  } = useStores();

  const { isDesktopMedia } = useMediaBreakpoints();

  const {
    subscription,
    isSubscribed,
    isProSubscription,
    proSubscriptionInterval,
    starterSubscriptionInterval,
    subGbAddonsAmount,
    isFreeSubscription,
    isCancellingSubscription,
    getExpireDate,
    getExpireDay,
    isCustomSubscription,
    storageLimit,
    subaccountsLimit,
    planInterval,
    currentPlan,
  } = subscriptionsStore;

  const areYouSureBoolean = useBoolean(false);
  const survey1stBoolean = useBoolean(false);
  const survey2ndBoolean = useBoolean(false);

  const [extraCount, setExtraCount] = useState(subGbAddonsAmount);

  const [proSubscriptionToSelect, setProSubscriptionToSelect] =
    useState<SupportedSubscriptionInterval>(proSubscriptionInterval ?? "monthly");

  const [starterSubscriptionToSelect, setStarterSubscriptionToSelect] =
    useState<SupportedSubscriptionInterval>("monthly");

  useDidMount(() => {
    userStore.loadUserMe();
    userStore.loadUserTagManagerInfo();
    subaccountsStore.loadSubaccounts();
  });

  useDidUpdate(() => {
    proSubscriptionInterval && setProSubscriptionToSelect(proSubscriptionInterval);
    starterSubscriptionInterval && setStarterSubscriptionToSelect(starterSubscriptionInterval);
  }, [proSubscriptionInterval]);

  useDidUpdate(
    async () => {
      if (userStore.isShopifyUser) await shopifyStore.loadShopifySettings();
    },
    [userStore.isShopifyUser],
    true,
  );

  useDidUpdate(() => setExtraCount(subGbAddonsAmount), [subGbAddonsAmount]);

  useDidUpdate(
    () => {
      // shopify subscription logic
      const isCancelNotInProgress = !isSubscribed && cancel_in_progress;
      const isPaymentNotInProgress = isSubscribed && payment_in_progress;

      if (isCancelNotInProgress || isPaymentNotInProgress) {
        setQueryParams({});
      }
    },
    [isSubscribed, payment_in_progress, cancel_in_progress],
    true,
  );

  useDidUpdate(() => {
    if (!areYouSureBoolean.value) return null;

    notificationStore.open({
      title: localeStore.t('settings.subscriptions["cancel-subscription-modal"].title'),
      message: (
        <>
          <NotificationTextSecondary>
            {localeStore.t(
              'settings.subscriptions["cancel-subscription-modal"]["days-remaining"].first',
            )}
            <NotificationTextSecondary tag="span" isStrong>
              {" "}
              {getExpireDay()}{" "}
            </NotificationTextSecondary>
            {localeStore.t(
              'settings.subscriptions["cancel-subscription-modal"]["days-remaining"].last',
            )}
          </NotificationTextSecondary>
          <br />
          <NotificationTextSecondary>
            {localeStore.t('settings.subscriptions["cancel-subscription-modal"].message')}{" "}
            <NotificationTextSecondary tag="span" isStrong>
              {getExpireDate()}
            </NotificationTextSecondary>
          </NotificationTextSecondary>
        </>
      ),
      confirmText: localeStore.t('settings.subscriptions["cancel-subscription-modal"]["ok-text"]'),
      cancelText: localeStore.t(
        'settings.subscriptions["cancel-subscription-modal"]["cancel-text"]',
      ),
      onOk: survey1stBoolean.setTruthy,
    });
  }, [areYouSureBoolean.value]);

  const survey1stFormRef = useRef<SubscriptionCancelSurvey1stForm>();

  //ternary is a crutch to silence mobx out of bonds console warning
  const shop = shopifyStore.shopifySettings.length
    ? shopifyStore.shopifySettings[0]?.shop ?? ""
    : "";

  const handleCreateShopifySubscription = () => {
    const planId = proPlanId;
    const priceId = proSubscriptionsTypePriceMap[proSubscriptionToSelect];
    const additionalPlanId = additionalSubGbAddonId;
    const additionalPriceId = additionalSubGbPriceMap[proSubscriptionToSelect];
    const shopURL = getShopifyStoreURL(shop);

    if (!shop) return dispatch(toggleShopifySelectStoreModal());

    if (!isSubscribed) {
      shopifyStore.createShopifySubscription({
        shop_url: shopURL,
        plan_id: planId,
        price_id: priceId,
      });
    } else {
      if (subscription.plan.id !== proSubscriptionsTypePriceMap[proSubscriptionToSelect]) {
        // remove current subscription and all addons, create new subscription
        handleChangeShopifySubscription({
          shopURL,
          planId,
          priceId,
          additionalPlanId,
          additionalPriceId,
        });
      } else {
        if (!extraCount) return;

        shopifyStore.addAdditionShopifySubscription({
          queryParams: queryStringify({
            shop_url: shopURL,
            plan_id: additionalPlanId,
            price_id: additionalPriceId,
            quantity: extraCount,
          }),
        });
      }
    }
  };

  const handleChangeShopifySubscription: HandleChangeShopifySubscription = async ({
    shopURL,
    planId,
    priceId,
  }) => {
    const planIdToCancel = subscription.id;

    // removing main subscription
    await shopifyStore.removeShopifySubscription({
      shop_url: shopURL,
      subscription_id: planIdToCancel,
    });

    // creating new subscription
    await shopifyStore.createShopifySubscription({
      shop_url: shopURL,
      plan_id: planId,
      price_id: priceId,
    });
  };

  const handleCancelSubscription = () => {
    if (userStore.isShopifyUser && !shop)
      if (!shop) return dispatch(toggleShopifySelectStoreModal());

    areYouSureBoolean.setTruthy();
  };

  const handleAreYouSureDowngrade = () => {
    const title = userStore.isShopifyUser
      ? localeStore.t('settings.subscriptions["downgrade-modal"].title.cancel')
      : localeStore.t('settings.subscriptions["downgrade-modal"].title.downgrade');
    const message = userStore.isShopifyUser
      ? localeStore.t('settings.subscriptions["downgrade-modal"].message.cancel')
      : `${localeStore.t(
          'settings.subscriptions["downgrade-modal"].message.downgrade.first',
        )} ${subaccountsLimit} ${localeStore.t(
          'settings.subscriptions["downgrade-modal"].message.downgrade.middle',
        )} ${storageLimit}GB ${localeStore.t(
          'settings.subscriptions["downgrade-modal"].message.downgrade.last',
        )} ${getExpireDate()}`;
    const confirmText = userStore.isShopifyUser
      ? localeStore.t('settings.subscriptions["downgrade-modal"]["ok-text"].cancel')
      : localeStore.t('settings.subscriptions["downgrade-modal"]["ok-text"].downgrade');

    areYouSureBoolean.setFalsy();

    notificationStore.open({
      title,
      message,
      confirmText,
      cancelText: localeStore.t('settings.subscriptions["downgrade-modal"]["cancel-text"]'),
      onOk: handleCancelSubscription,
    });
  };

  const handleRenewClick = () => {
    notificationStore.open({
      title: localeStore.t('settings.subscriptions["renew-modal"].title'),
      message: (
        <NotificationTextSecondary>
          {localeStore.t('settings.subscriptions["renew-modal"].message.top')}
          <br />
          <NotificationTextSecondary isStrong>
            {" "}
            {localeStore.t('settings.subscriptions["renew-modal"].message.middle')}
            {getExpireDate()}.
          </NotificationTextSecondary>
          <br />
          <NotificationTextSecondary isStrong>
            {localeStore.t('settings.subscriptions["renew-modal"].message.last')}
          </NotificationTextSecondary>
        </NotificationTextSecondary>
      ),
      confirmText: localeStore.t('settings.subscriptions["renew-modal"]["ok-text"]'),
      cancelText: localeStore.t('settings.subscriptions["renew-modal"]["cancel-text"]'),
      confirmAppearance: "primary",
      onOk: async () => {
        await subscriptionsStore.renewSubscription();
        await subscriptionsStore.loadSubscription();
        notificationStore.open({
          title: localeStore.t('settings.subscriptions["renew-modal"].success.title'),
          message: (
            <NotificationText disableBold>
              {localeStore.t('settings.subscriptions["renew-modal"].success.message')}
            </NotificationText>
          ),
          confirmText: localeStore.t('settings.subscriptions["renew-modal"].success["ok-text"]'),
          cancelText: "",
          onlyConfirm: true,
          confirmAppearance: "primary",
          icon: <SvgIcon icon={SuccessSVG} size={195} />,
        });
      },
    });
  };

  const handleSubmitFirstSurvey = (res: SubscriptionCancelSurvey1stForm) => {
    survey1stFormRef.current = res;
    survey1stBoolean.setFalsy();
    notificationStore.open({
      title: localeStore.t('settings.subscriptions["first-survey-modal"].title'),
      message: (
        <NotificationTextSecondary>
          {localeStore.t('settings.subscriptions["first-survey-modal"].message')}{" "}
          <NotificationTextSecondary tag="span" isStrong>
            {getExpireDate()}.
          </NotificationTextSecondary>
          <br />
        </NotificationTextSecondary>
      ),
      confirmText: localeStore.t('settings.subscriptions["first-survey-modal"]["ok-text"]'),
      cancelText: localeStore.t('settings.subscriptions["first-survey-modal"]["cancel-text"]'),
      confirmAppearance: "primary",
      onOk: survey2ndBoolean.setTruthy,
    });
  };

  const handleSubmitSecondSurvey = async (res: SubscriptionCancelSurvey2ndForm) => {
    notificationStore.openLoader({
      loaderType: "circle-loader",
    });
    survey2ndBoolean.setFalsy();

    if (userStore.userMe?.hubspotID) {
      hubspotStore.addHubspotData({
        request_url: `https://api.hubapi.com/contacts/v1/contact/vid/${userStore.userMe?.hubspotID}/profile`,
        request_method: "POST",
        request_body: {
          properties: [
            {
              property: "hub_cancellation_reason",
              value: survey1stFormRef.current?.whyCancel || "",
            },
            {
              property: "hub_most_useful_features",
              value: survey1stFormRef.current?.whatUseFull || "",
            },
            {
              property: "hub_retention_feature_request",
              value: survey1stFormRef.current?.whatLiked || "",
            },
            { property: "hub_triggered_to_cancel", value: res.whatGood || "" },
            {
              property: "hub_retention_prevented_from_cancelling",
              value: res.howToPrevent || "",
            },
            { property: "hub_cancellation_interview", value: res.wantInterview || "" },
          ],
        },
      });
    }

    if (userStore.isShopifyUser) {
      const shopURL = getShopifyStoreURL(shop);

      shopifyStore.removeShopifySubscription({
        shop_url: shopURL,
        subscription_id: subscription.id,
      });
    } else {
      await subscriptionsStore.removeSubscription();
      await subscriptionsStore.loadSubscription();
      notificationStore.open({
        title: localeStore.t('settings.subscriptions["second-survey-success"].title'),
        message: (
          <NotificationText disableBold>
            {localeStore.t('settings.subscriptions["second-survey-success"].message')}
          </NotificationText>
        ),
        confirmText: localeStore.t('settings.subscriptions["second-survey-success"]["ok-text"]'),
        cancelText: "",
        onlyConfirm: true,
        confirmAppearance: "primary",
        icon: <SvgIcon icon={SuccessSVG} size={195} />,
      });
    }

    getUserTagManagerInfoCallback((response) => {
      pushDataLayerEvent({
        event: "downgrade_completed",
        event_params: {
          user_id: response?.user_id ?? "couldn't get data",
          prev_account_type: userStore.userTagManagerInfo?.account_type ?? "couldn't get data",
          prev_paid_plan: userStore.userTagManagerInfo?.current_paid_plan ?? "couldn't get data",
          new_account_type: response?.account_type ?? "couldn't get data",
          new_paid_plan: response?.current_paid_plan ?? "couldn't get data",
          is_trial: response?.isTrial ?? "couldn't get data",
        },
      });
    });

    notificationStore.closeLoader();
  };

  const handleProceedToPayment = (
    plan: "starter" | "pro",
    { extraCount, coupon }: BillingExtraData,
    interval?: SupportedSubscriptionInterval,
  ) => {
    if (userStore.isShopifyUser) return handleCreateShopifySubscription();

    const subscriptionToSelect =
      interval ?? (plan === "pro" ? proSubscriptionToSelect : starterSubscriptionToSelect);

    const paymentSuccess = async () => {
      await subscriptionsStore.loadSubscriptionPlans();
      await subscriptionsStore.loadSubscription();

      if (subscriptionsStore.isProSubscription) {
        pushUserAttributeDataLayerEvent({
          is_pro_user: true,
        });
      }
      !!window?.$platform?.isCompact &&
        userStore.parentPostMessage(null, MessageTypes.subscriptionsSuccess);
      notificationStore.open({
        title: localeStore.t('settings.subscriptions["payment-modal-success"].title'),
        message: (
          <NotificationBlock>
            {(isFreeSubscription || currentPlan?.name.toLowerCase() !== plan) && (
              <NotificationText disableBold>
                {localeStore.t('settings.subscriptions["payment-modal-success"].message.top')}
                <span>
                  {subscriptionToSelect === "monthly" &&
                    ` ${capitalize(plan)} ${localeStore.t(
                      'settings.subscriptions["payment-modal-success"].message.monthly',
                    )}`}
                  {subscriptionToSelect === "yearly" &&
                    ` ${capitalize(plan)} ${localeStore.t(
                      'settings.subscriptions["payment-modal-success"].message.yearly',
                    )}`}
                </span>
              </NotificationText>
            )}
            {!isFreeSubscription && currentPlan?.name.toLowerCase() === plan && (
              <NotificationText disableBold>
                {localeStore.t('settings.subscriptions["payment-modal-success"].message.bottom')}
              </NotificationText>
            )}
          </NotificationBlock>
        ),
        confirmText: isFreeSubscription
          ? localeStore.t('settings.subscriptions["payment-modal-success"]["ok-text"].start')
          : localeStore.t('settings.subscriptions["payment-modal-success"]["ok-text"].back'),
        cancelText: "",
        onlyConfirm: true,
        icon: <SvgIcon icon={SuccessSVG} size={195} />,
        onOk: () => {
          window.location.reload();
        },
      });

      modalsStore.close("BillingModal");
    };

    const downgrade = extraCount - subGbAddonsAmount < 0;
    if (downgrade) {
      putSubscriptionAdditional
        .getRequest({
          queryParams: `?priceID=${additionalSubGbPriceMap[subscriptionToSelect]}&quantity=${extraCount}`,
        })
        .fetch()
        .then((res) => {
          if (res.status === "success") paymentSuccess();
        })
        .catch((err) => {});
      return;
    }

    modalsStore.open("PaymentModal", {
      planToSelect: plan,
      coupon,
      addonsIds: extraCount,
      subscriptionToSelect: subscriptionToSelect,
      onPaymentSuccess: async () => {
        await paymentSuccess();
      },
    });
  };

  const handleChangeBillingPeriodInCurrentPlan = () => {
    if (!planInterval) return;

    const plan = isProSubscription ? "pro" : "starter";

    modalsStore.open("BillingModal", {
      offer: plan,
      subscriptionType: oppositeSubscriptionsMap[planInterval],
      extraCountValue: extraCount,
      canDisplayAddons: checkIfCanDisplayAddons(),
      isPaymentDisabled: shopifyStore.isPaymentDisabled,
      onProceed: (data) => {
        handleProceedToPayment(plan, data, oppositeSubscriptionsMap[planInterval]);
      },
      onExtraCountChange: setExtraCount,
    });
  };

  const handleChangeBillingPeriodInPlan = (
    interval: SupportedSubscriptionInterval,
    plan: "starter" | "pro",
  ) => {
    if (userStore.isShopifyUser) {
      if (
        (plan === "pro" && interval !== proSubscriptionInterval) ||
        (plan === "starter" && interval !== starterSubscriptionInterval)
      ) {
        setExtraCount(0);
      } else {
        setExtraCount(subGbAddonsAmount);
      }
    }

    plan === "pro"
      ? setProSubscriptionToSelect(interval)
      : setStarterSubscriptionToSelect(interval);
  };

  const canDisplayRenewPanel = isCancellingSubscription && !isCustomSubscription;

  const checkIfCanDisplayCurrentPlan = () => {
    let result = !isCancellingSubscription || isCustomSubscription;

    if (userStore.isShopifyUser) result = isSubscribed;

    return result;
  };

  const checkIfCanDisplayAddons = () => {
    let result = true;

    if (userStore.isShopifyUser) {
      result =
        isSubscribed &&
        subscription.plan.id === proSubscriptionsTypePriceMap[proSubscriptionToSelect];
    }

    return result;
  };

  if (userStore.isSubaccount) return <DefaultRedirect />;

  return (
    <div className={clsx(styles.subscriptions, globalStyles.hideScrollBar)}>
      {userStore.userMe &&
        !userStore.userMe._cache &&
        userStore.userMe.canActivateLifetimeSubscription && (
          <div className={styles.laNotify}>
            <KeyIcon />
            <div
              className={styles.laNotifyTitle}
              dangerouslySetInnerHTML={{
                __html: localeStore.t(
                  'activate["lifetime-plan"].notification',
                  'Activate your <span style="color: #F25700">Lifetime GemHub subscription</span> today. Get ready to unlock all the amazing features and benefits',
                ),
              }}
            />
            <Button className={styles.laNotifyBtn}>
              <a href="/lifetime-activate">Activate</a>
            </Button>
          </div>
        )}

      {(userStore.loadingMe || subscriptionsStore.isLoadingPlans) && (
        <Loader position="absolute" withOverlay />
      )}
      {!isDesktopMedia && <MobileTabHeader title={localeStore.t("settings.subscriptions.title")} />}
      {canDisplayRenewPanel ? (
        <RenewPanel onRenewClick={handleRenewClick} />
      ) : (
        checkIfCanDisplayCurrentPlan() && (
          <CurrentPlanOverview
            onChangeBillingPeriodClick={handleChangeBillingPeriodInCurrentPlan}
            onCancelSubscription={handleAreYouSureDowngrade}
          />
        )
      )}
      {payment_in_progress && !isSubscribed && (
        <ShopifySubscriptionInProgress
          label={localeStore.t('settings.subscriptions["shopify-payment-in-process"]')}
        />
      )}
      {cancel_in_progress && isSubscribed && (
        <ShopifySubscriptionInProgress
          label={localeStore.t('settings.subscriptions["shopify-subscription-in-cancel"]')}
        />
      )}
      {!payment_in_progress && !cancel_in_progress && (
        <Content
          proSubscriptionToSelect={proSubscriptionToSelect}
          starterSubscriptionToSelect={starterSubscriptionToSelect}
          activeSubaccountAmount={subaccountsStore.subaccountList.length}
          extraCount={extraCount}
          isPaymentDisabled={shopifyStore.isPaymentDisabled}
          setSubscriptionToSelect={handleChangeBillingPeriodInPlan}
          canDisplayAddons={checkIfCanDisplayAddons()}
          onDowngrade={handleAreYouSureDowngrade}
          onProceed={handleProceedToPayment}
        />
      )}
      <SubscriptionCancelSurvey1st
        isOpen={survey1stBoolean.value}
        onSubmit={handleSubmitFirstSurvey}
        setClose={survey1stBoolean.setValue}
      />
      <SubscriptionCancelSurvey2nd
        isOpen={survey2ndBoolean.value}
        onSubmit={handleSubmitSecondSurvey}
        setClose={survey2ndBoolean.setValue}
      />
    </div>
  );
});

export default Subscriptions;
