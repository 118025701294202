import { Nullable } from "@gemlightbox/core-kit";

import {
  GlobalImageDataModel,
  GlobalLinkModel,
  ProductImageModel,
  ProductTypeModel,
} from "src/models";

export enum MediaType {
  image = "image",
  video = "video",
  video360 = "video360",
  blink = "blink",
  pdf = "pdf",
}

export enum MediaDownloadType {
  jpg = "jpg",
  png = "png",
  pdf = "pdf",
}

export type MediaTypeKeys = keyof typeof MediaType;

export type MediaBlinkData = {
  arHeight: string; // "36.66"
  arHeightUnit: string;
  arWidth: string; // "36.66"
  arWidthUnit: string;
  arOffsetRotation: number;
  arOffsetX: number;
  arOffsetY: number;
  arTemplateId: number;
  productTypeId: ProductTypeModel["id"];
  productType: ProductTypeModel["name"];
  blinkFile?: GlobalImageDataModel;
  cropFile?: GlobalImageDataModel;
};

export type MediaModel = MediaBlinkData &
  MediaShortModel & {
    sku: Nullable<string>;
    filename: string;
    created_at: string; // "2021-09-15T18:11:19.671Z"
    updated_at: string; // "2021-09-15T18:11:19.671Z"
    productCategory: string; // "5"
    original: string;
    url: string;
    thumbnail: string;
    interactive: string;
    gif: string;
    type: MediaTypeKeys;
    id: number;
    sku_id: Nullable<number>;
    views: number;
    created: {
      first_name: string;
      last_name: string;
      email: string;
      role: string;
      _id: number;
    };
    file?: GlobalImageDataModel;
    link: GlobalLinkModel;
    arData: MediaBlinkData;
    metaData?: DeviceMetaDataModel;
    status?: Nullable<PendingUploadStatus>;
  };

export enum PendingUploadStatus {
  complete = 0,
  pending = 1,
}

export type MediaShortModel = {
  id: number;
  file?: GlobalImageDataModel;
  link: GlobalLinkModel;
  product: any;
  metaData?: DeviceMetaDataModel;
};

export type MediaCollectionItemModel = ProductImageModel & {
  id: number;
  user_id: number;
  type: MediaTypeKeys;
  size: number;
  sort_index: number;
  file?: GlobalImageDataModel;
  guid: string;
  createdAt: string;
  updatedAt: string;
  position: number;
  link: GlobalLinkModel;
  products: Array<{
    _id: number;
    title: string;
    link: GlobalLinkModel;
  }>;
};

export type MediaCollectionModel = {
  name: string;
  description: string;
  sub_title: string;
  resourceType: string;
  user_id: number;
  id: number;
  link: GlobalLinkModel;
  medias: MediaCollectionItemModel[];
};

export type MediaMetadataModel = {
  small?: string;
  medium?: string;
  compressed?: string;
  data?: any;
  media_id?: number;
  original: string;
  createdAt?: Date;
  filename?: string;
  interactive?: string;
  preview?: string;
  gif?: string;
  size?: number;
  type?: string;
};

export type LogoWatermarkModel = {
  id?: number;
  logo: string;
  ratios?: LogoWatermarkRatioModel[];
  user_id?: number;
  createdAt?: string;
  updatedAt?: string;
};

export type LogoWatermarkRatioModel = {
  size?: number[];
  opacity?: number;
  position?: number[];
  rotation?: number;
  aspectRatio?: string;
};

export type DeviceMetaDataModel = {
  deviceName?: string | null;
  manufacturer?: string | null;
  serialNumber?: string | null;
  cameraName?: string | null;
  cameraManufacturer?: string | null;
  cameraSerialNumber?: string | null;
  isAiRetouch?: boolean;
  isAiRemoveDust?: boolean;
  isAiDescription?: boolean;
  isAiBackgroundRemoval?: boolean;
  isTransparentBackground?: boolean;
  isModelImage?: boolean;
  isEclipseMode?: boolean;
};

export type CategoriesModel = {
  item_name: string;
  total_items: number;
  item_details?: Omit<
    DeviceMetaDataModel,
    | "isAiRetouch"
    | "isModelImage"
    | "isAiRemoveDust"
    | "isAiDescription"
    | "isAiBackgroundRemoval"
    | "isEclipseMode"
  >;
  filtered_items?: MediaCollectionItemModel[];
};

export type CategoriesArrayModel = {
  categories: Array<CategoriesModel>;
};
