import * as XLSX from "xlsx";
import { uniqId } from "@gemlightbox/core-kit";

import { ColumnType, ColumnValuesListType, ColumnValueType } from "src/store";
import { acceptedExt } from "./products-import-modal.constants";

type ProcessFileDataOptions = {
  filterFirstRow: boolean;
};

export const processFiles = (files: File[]): Promise<ColumnValuesListType[]> => {
  return new Promise((resolve, reject) => {
    try {
      files.forEach((file) => {
        const ext = file.name.split(".").pop();
        if (!ext || !acceptedExt.includes(ext)) {
          console.error(`Provided file extension is not acceptable: ${ext}`);
          return;
        }

        const reader = new FileReader();

        reader.onload = (e) => {
          try {
            const data = e.target?.result;
            const workbook = XLSX.read(data, {
              type: "binary",
            });
            workbook.SheetNames.forEach((sheetName) => {
              const objectData = XLSX.utils.sheet_to_json<ColumnValueType[]>(
                workbook.Sheets[sheetName],
                {
                  header: 1,
                },
              );
              resolve(objectData);
            });
          } catch (e) {
            reject(e);
          }
        };
        file.type === "text/csv" ? reader.readAsText(file) : reader.readAsArrayBuffer(file);
      });
    } catch (e) {
      reject(e);
    }
  });
};

export const extractColumn = (arr: Array<ColumnValueType[]>, index: number): ColumnType => {
  return arr.reduce(
    (acc, curr) => {
      const data = curr[index];

      return {
        ...acc,
        values: [...acc.values, data],
      };
    },
    {
      displayName: "",
      name: "",
      nameID: "",
      namesGroupID: "",
      values: [],
      id: uniqId(),
    } as ColumnType,
  );
};

export const processFileData = (
  fileData: ColumnValuesListType[],
  options: ProcessFileDataOptions = {
    filterFirstRow: false,
  },
): ColumnType[] => {
  const result: ColumnType[] = [];
  let filteredFileData = fileData.map((values) => Array.from(values, (item) => item || ""));

  let maxLength = 0;
  let maxIndex = 0;

  filteredFileData.forEach((values, i) => {
    if (values.length > maxLength) {
      maxIndex = i;
      maxLength = values.length;
    }
  });

  let fileDataToProcess = fileData;
  if (options.filterFirstRow) {
    fileDataToProcess = fileDataToProcess.slice(1);
  }

  filteredFileData[maxIndex].forEach((_, index) => {
    const data = extractColumn(fileDataToProcess, index);
    result.push(data);
  });

  return result;
};
