import { extendObservable, isObservable, makeAutoObservable, runInAction, toJS } from "mobx";
import {
  ApiRequest,
  ApiRequestProgressInfo,
  ExtractApiRequest,
  ExtractApiResponse,
  isEqual,
  MaybeArray,
  Nullable,
  ObjectType,
  PAGINATION_OPTIONS,
  PaginationList,
  toArrayOptions,
} from "@gemlightbox/core-kit";
import { getAttributesForRequest } from "src/utils";
import {
  MediaModel,
  ProductModel,
  MediaMetadataModel,
  CategoriesModel,
  PendingUploadStatus,
} from "src/models";
import {
  getMedias,
  putAssignMediaToProduct,
  deleteUnAssignMedia,
  deleteMedia,
  putUpdateMedia,
  postUploadFiles,
  postUploadMedia,
  postCreateMediaCollection,
  putUpdateMedias,
  putSaveAIResult,
  PostUploadMediaData,
  getGroups,
} from "src/api";
import { pushDataLayerEvent } from "src/utils";
import { MediaFilters } from "src/containers/media/media.types";
import {
  ExtendedCategoriesModel,
  ExtendedMediaModel,
  MediaFiltersQueryType,
} from "./media.store.types";
import { useStores } from "src/hooks";
export class MediaStore {
  private readonly _initialFilters: MediaFilters = {
    page: 1,
    limit: PAGINATION_OPTIONS[0].value,
    sort: "all",
    createdFrom: "",
    createdTo: "",
    type: "all",
    sku: "",
    user_id: "all",
    isPendingUpload: false,
  };

  private _prevRequest: Nullable<ApiRequest<ExtractApiResponse<typeof getMedias>>> = null;

  private _loading = false;
  public get loading() {
    return this._loading;
  }

  private _isAssigning = false;
  public get isAssigning() {
    return this._isAssigning;
  }

  private _totalMediaAmount = 0;
  public get totalMediaAmount() {
    return this._totalMediaAmount;
  }

  private _totalMediaPendingUploadAmount = 0;
  get totalMediaPendingUploadAmount() {
    return this._totalMediaPendingUploadAmount;
  }

  private _mediaPendingUploadList: ExtendedMediaModel[] = [];
  get mediaPendingUploadList() {
    return this._mediaPendingUploadList;
  }

  private _categoriesList: ExtendedCategoriesModel[] = [];
  public get categoriesList() {
    return this._categoriesList;
  }

  private _resultsMediaAmount = 0;
  public get resultsMediaAmount() {
    return this._resultsMediaAmount;
  }

  public get isEmpty() {
    return this.totalMediaAmount === 0;
  }

  private _mediaList: ExtendedMediaModel[] = [];
  public get mediaList() {
    return this._mediaList;
  }

  public get mediaAmount() {
    return this.mediaList.length;
  }

  public get selectedMediaList() {
    return this.mediaList.filter(({ extended: { selected } }) => selected);
  }

  public get selectedMediaAmount() {
    return this.selectedMediaList.length;
  }

  public get isPendingUploedMediaSelected() {
    return (
      this.selectedMediaList.findIndex((media) => media?.status === PendingUploadStatus.pending) >=
      0
    );
  }

  public get isEclipseModeMediaSelected() {
    return this.selectedMediaList.findIndex((media) => media?.metaData?.isEclipseMode) >= 0;
  }

  public get isAiRetouchMediaSelected() {
    return this.selectedMediaList.findIndex((media) => media?.metaData?.isAiRetouch) >= 0;
  }

  public get isAiBackgroundRemovalMediaSelected() {
    return this.selectedMediaList.findIndex((media) => media?.metaData?.isAiBackgroundRemoval) >= 0;
  }

  public get isTransparentBackgroundMediaSelected() {
    return (
      this.selectedMediaList.findIndex((media) => media?.metaData?.isTransparentBackground) >= 0
    );
  }

  public get isAiRemoveDustMediaSelected() {
    return this.selectedMediaList.findIndex((media) => media?.metaData?.isAiRemoveDust) >= 0;
  }

  public get isMediaSelected() {
    return this.selectedMediaAmount !== 0;
  }

  public get isSingleMediaSelected() {
    return this.selectedMediaAmount === 1;
  }

  public get isMultipleMediaSelected() {
    return this.selectedMediaAmount > 1;
  }

  public get isEveryMediaSelected() {
    return this.selectedMediaAmount === this.mediaAmount;
  }

  public get loadingMediaList() {
    return this.mediaList.filter(({ extended: { loading } }) => loading);
  }

  private _filters: MediaFilters = this._initialFilters;
  public get filters() {
    return this._filters;
  }

  public get totalPages() {
    return Math.ceil(this.resultsMediaAmount / (this.filters.limit || this.resultsMediaAmount));
  }

  public get isSearch() {
    return !!this.filters.sku;
  }

  public get isSort() {
    return this.filters.sort !== "all";
  }

  public get isSortedByDate() {
    return !!this.filters.createdFrom && !!this.filters.createdTo;
  }

  public get isUser() {
    return this.filters.user_id !== "all";
  }

  public get isFilters() {
    return this.isSort || this.isUser || this.isSortedByDate;
  }

  public get isOnlySearch() {
    return this.isSearch && !this.isFilters;
  }

  public get filtersAmount() {
    let result = 0;

    if (this.isSort) result += 1;
    if (this.isUser) result += 1;
    if (this.isSortedByDate) result += 1;

    return result;
  }

  private _updatingMedia = false;
  public get updatingMedia() {
    return this._updatingMedia;
  }

  private _uploadingMedia = false;
  public get uploadingMedia() {
    return this._uploadingMedia;
  }

  private _uploadingProgress: Nullable<ApiRequestProgressInfo> = null;
  public get uploadingProgress() {
    return this._uploadingProgress;
  }

  private _loadingAIResult = false;
  public get loadingAIResult() {
    return this._loadingAIResult;
  }

  private _uploadRequest: Nullable<ApiRequest<object[], any, ObjectType>> = null;
  public get uploadRequest() {
    return this._uploadRequest;
  }

  private _saveRequest: any = null;
  public get saveRequest() {
    return this._saveRequest;
  }

  private _uploadedVideos: MediaMetadataModel[];
  public get uploadedVideos() {
    return this._uploadedVideos;
  }

  private _preUploadStatus = 0;
  public get preUploadStatus() {
    return this._preUploadStatus;
  }

  public setPreUploadStatus(value: number) {
    this._preUploadStatus = value;
  }

  private _preUploadFiles: any = [];
  public get preUploadFiles() {
    return this._preUploadFiles;
  }

  public setPreUploadFiles(files: any) {
    this._preUploadFiles = files;
  }

  private _savedPostUploadMedia: PostUploadMediaData;

  public get savedPostUploadMedia() {
    return this._savedPostUploadMedia;
  }

  public setSavedPostUploadMedia(savedPostUploadMedia: PostUploadMediaData) {
    this._savedPostUploadMedia = savedPostUploadMedia;
  }

  private _savedTitleValue = "";
  public get savedTitleValue() {
    return this._savedTitleValue;
  }

  public setSavedTitleValue(title: string) {
    this._savedTitleValue = title;
  }

  private _savedDescriptionValue = "";
  public get savedDescriptionValue() {
    return this._savedDescriptionValue;
  }

  public setSavedDescriptionValue(description: string) {
    this._savedDescriptionValue = description;
  }

  private _savedProductType: string | undefined;
  public get savedProductType() {
    return this._savedProductType;
  }

  public setSavedProductType(type: string | undefined) {
    this._savedProductType = type;
  }

  private _savedSelectedSKUOption: number | undefined;
  public get savedSelectedSKUOption() {
    return this._savedSelectedSKUOption;
  }

  public setSavedSelectedSKUOption(type: number | undefined) {
    this._savedSelectedSKUOption = type;
  }

  private _savedProgress = 0;
  public get savedProgress() {
    return this._savedProgress;
  }

  public setSavedProgress(progress: number) {
    this._savedProgress = progress;
  }

  private _savedStatus = 0; // 0-init 1-saving 2-success 3-error
  public get savedStatus() {
    return this._savedStatus;
  }

  public setSavedStatus(status: number) {
    runInAction(() => {
      this._savedStatus = status;
    });
  }

  private _savedCancelImg = "";
  public get savedCancelImg() {
    return this._savedCancelImg;
  }

  public setSavedCancelImg(img: string) {
    this._savedCancelImg = img;
  }

  private _sidebarOpened = false;
  public get sidebarOpened() {
    return this._sidebarOpened;
  }

  public setSidebarOpened(opened: boolean) {
    this._sidebarOpened = opened;
  }

  private _scrollPosition: { left: number; top: number } = { left: 0, top: 0 };
  public get scrollPosition() {
    return { ...this._scrollPosition };
  }
  public setScrollPosition(position: { left: number; top: number }) {
    this._scrollPosition = { ...position };
  }

  private _scrollRestoration = false;
  public get scrollRestoration() {
    return this._scrollRestoration;
  }
  public setScrollRestoration(scrollRestoration: boolean) {
    this._scrollRestoration = scrollRestoration;
  }

  constructor() {
    makeAutoObservable(this);
  }

  /* Requests ↓ */
  public async loadMediaList(filters?: MediaFilters) {
    // Note: If you try to load medias with filters, and they are the same with
    // already stored one -> do nothing
    if (filters && isEqual(filters, this.filters)) return;

    if (this._prevRequest) this._prevRequest.abort();

    runInAction(() => {
      this._loading = true;
      if (filters) this.setFilters(filters);
    });

    const request = getMedias.getRequest({
      queryParams: this._getRequestFilters(),
    });
    this._prevRequest = request;
    const { success, details } = await request.fetch();
    const { isCanceled } = details;

    if (isCanceled) return;

    runInAction(() => {
      if (success) {
        this._setMediaList(success);
      } else {
        this._totalMediaAmount = 0;
        this._resultsMediaAmount = 0;
        this._mediaList = [];
      }

      this._prevRequest = null;
      this._loading = false;
    });
  }

  public async loadMediaPendingUploadList(page = 1, limit = 3) {
    const isPendingUpload = true;
    const { success } = await getMedias
      .getRequest({
        queryParams: {
          ...this._getRequestFilters(),
          page,
          limit,
          isPendingUpload,
        },
      })
      .fetch();

    if (success) {
      if (page === 1) {
        this._setMediaPendingUploadList(success);
      } else {
        const prevProductList = this._mediaPendingUploadList;
        this._setMediaPendingUploadList(success);
        this._mediaPendingUploadList = prevProductList.concat(this._mediaPendingUploadList);
      }
    }
  }

  public async loadMediaPendingUploadGroupList(groupBy = "deviceName", isPendingUpload = true) {
    const { success } = await getGroups
      .getRequest({
        queryParams: {
          groupBy,
          isPendingUpload,
        },
      })
      .fetch();

    if (success?.categories) {
      this._setCategoriesList(success.categories);
    }
  }

  public async getMediaPendingUploadGroupList(
    serialNumber?: string,
    groupBy = "thumbnail",
    isPendingUpload = true,
  ) {
    const { success } = await getGroups
      .getRequest({
        queryParams: {
          groupBy,
          isPendingUpload,
          serialNumber,
        },
      })
      .fetch();

    return success;
  }

  public async uploadMediaList(filters?: MediaFilters) {
    if (this._loading || (filters && isEqual(filters, this.filters))) return;

    runInAction(() => {
      this._loading = true;
      if (filters) this.setFilters(filters);
    });

    const request = getMedias.getRequest({
      queryParams: this._getRequestFilters(),
    });

    this._prevRequest = request;
    const { success, details } = await request.fetch();
    const { isCanceled } = details;

    if (isCanceled) return;

    runInAction(() => {
      if (success) {
        const prevMediaList = this._mediaList;
        this._setMediaList(success);
        this._mediaList = prevMediaList.concat(this._mediaList);
      } else {
        this._totalMediaAmount = 0;
        this._resultsMediaAmount = 0;
        this._mediaList = [];
      }

      this._loading = false;
    });
  }

  public async assignMedia(mediaIds: MaybeArray<MediaModel["id"]>, product: ProductModel) {
    const medias = this.getMediasByIds(mediaIds);

    runInAction(() => {
      this._isAssigning = true;

      if (medias.length) this.toggleMediaList(medias, "loading");
    });

    const result = await putAssignMediaToProduct
      .getRequest({
        params: { productId: product._id },
        queryParams: mediaIds.toString(),
      })
      .fetch();

    runInAction(() => {
      this._isAssigning = false;

      if (medias.length) {
        this.toggleMediaList(medias, "loading");

        if (result.status === "success") {
          medias.forEach((media) => this.updateMediaAssignLocal(media, product));
        }
      }
    });

    return result;
  }

  public async unassignMedia(media_id: ExtendedMediaModel["id"]) {
    const media = this.getMediaById(media_id);

    runInAction(() => {
      this._isAssigning = true;
      if (media) this.toggleMediaList(media, "loading");
    });

    const result = await deleteUnAssignMedia.getRequest({ params: { media_id } }).fetch();

    runInAction(() => {
      this._isAssigning = false;

      if (media) {
        this.toggleMediaList(media, "loading");

        if (result.status === "success") {
          this.updateMediaAssignLocal(media, null);
        }
      }
    });

    return result;
  }

  public async deleteMedia(medias: ExtendedMediaModel[]) {
    pushDataLayerEvent({ event: "delete_media" });

    const ids = medias.map(({ id }) => id);

    runInAction(() => {
      this.toggleMediaList(medias, "loading");
    });

    const { success } = await deleteMedia
      .getRequest({
        queryParams: { ids: ids.toString(), ...this._getRequestFilters() },
      })
      .fetch();

    runInAction(() => {
      if (success) this._setMediaList(success);

      this.toggleMediaList(medias, "loading");
    });
  }

  public async updateMedia(
    id: ExtendedMediaModel["id"],
    data: ExtractApiRequest<typeof putUpdateMedia>,
  ) {
    if (this.updatingMedia) return Promise.reject("Media already updating");

    let dataToSend: Nullable<FormData> = data instanceof FormData ? data : null;

    if (!(data instanceof FormData)) {
      const formData = new FormData();
      formData.append("files", data.files);
      if (data.type) formData.append("type", data.type);
      dataToSend = formData;
    }

    if (!dataToSend) return Promise.reject("Invalid data passed");

    runInAction(() => {
      this._updatingMedia = true;
    });

    const result = await putUpdateMedia
      .getRequest({
        params: { id },
        data: dataToSend,
        queryParams: this._getRequestFilters(),
      })
      .fetch();

    if (result?.error && result.error?.originalError?.code === "limit:storage_exceeded") {
      const { modalsStore } = useStores();
      runInAction(() => {
        modalsStore.open("CloudStorageFullModal", {
          formattedMessage: result.error.formattedMessage,
        });
      });
    }

    runInAction(() => {
      this._updatingMedia = false;
      if (result.success) this._setMediaList(result.success);
    });

    return result;
  }

  public async updateMedias(data: ExtractApiRequest<typeof putUpdateMedias>) {
    if (this.updatingMedia) return Promise.reject("Medias already updating");

    let dataToSend: Nullable<FormData> = data instanceof FormData ? data : null;

    if (!(data instanceof FormData)) {
      const formData = new FormData();

      data.forEach(({ files, types, ids }) => {
        formData.append("files", files);
        formData.append("types", types);
        formData.append("ids", JSON.stringify(ids));
      });

      dataToSend = formData;
    }

    if (!dataToSend) return Promise.reject("Invalid data passed");

    runInAction(() => {
      this._updatingMedia = true;
    });

    const result = await putUpdateMedias
      .getRequest({
        data: dataToSend,
        queryParams: this._getRequestFilters(),
      })
      .fetch();

    runInAction(() => {
      this._updatingMedia = false;
      if (result.success) this._setMediaList(result.success);
    });

    return result;
  }

  public async handleSave() {
    if (this._savedStatus === 1) {
      const event_params = {
        product_sku: this.savedPostUploadMedia[0]?.sku,
        product_type: this.savedPostUploadMedia[0]?.productType,
        media_filenames: this.savedPostUploadMedia
          .filter((d) => !d.types.includes("video"))
          .map((f) => f.files.name),
        preuploaded_media_urls: this.uploadedVideos?.map((f) => f.original),
      };
      pushDataLayerEvent({
        event: "gemhub:camera:media:cloud_save:started",
        event_params: { ...event_params, run_in_background: true },
      });

      const { attributesStore } = useStores();
      const parameters = getAttributesForRequest(
        {
          detailedTitle: this._savedTitleValue,
          description: this._savedDescriptionValue,
          productType: this._savedProductType,
        },
        attributesStore.attributes,
      ).filter((item) => item.value);

      const data: any = {
        description: this._savedDescriptionValue,
        detailedTitle: this._savedTitleValue,
      };
      if (parameters && parameters.length) {
        data.parameters = parameters;
      }
      await this.uploadMedia(this._savedPostUploadMedia, {}, data).then((res) => {
        if (res.status === "success") {
          pushDataLayerEvent({
            event: "gemhub:camera:media:cloud_save:success",
            event_params,
          });
          runInAction(() => {
            if (this._savedStatus === 1) {
              this._savedStatus = 2;
              this._savedProgress = 0;
            }
            this._preUploadStatus = 0;
            this._savedPostUploadMedia = [];
          });
        } else {
          pushDataLayerEvent({
            event: "gemhub:camera:media:cloud_save:error",
            event_params: {
              ...event_params,
              http_status_code: res.details.statusCode,
              error_message: res.error,
            },
          });
          runInAction(() => {
            if (this._savedStatus === 1) {
              this._savedStatus = 3;
            } else {
              this._savedStatus = 0;
            }
          });
        }
      });
    }
  }

  public uploadMediaSync(data: ExtractApiRequest<typeof postUploadMedia>) {
    this._preUploadStatus = 1;
    let dataToSend: Nullable<FormData> = data instanceof FormData ? data : null;
    this._uploadedVideos = [];

    if (!(data instanceof FormData)) {
      const formData = new FormData();

      data.forEach(({ files, types }) => {
        formData.append("files", files);
        formData.append("types", types);
      });
      dataToSend = formData;
    }

    const request = postUploadFiles.getRequest({
      data: dataToSend,
    });

    this._uploadRequest = request;

    request.events.on("requestProgress", (data) => {
      // console.log(data.progress, this._savedProgress, 8888);
      if (this._savedProgress < 80) {
        runInAction(() => {
          this._savedProgress = data.progress === 100 ? 85 : data.progress;
        });
      }
    });

    request.events.on("success", async (res) => {
      pushDataLayerEvent({
        event: "gemhub:camera:media:preupload:success",
        event_params: {
          media_filenames: dataToSend?.getAll("files").map((f: any) => f.name),
          preuploaded_media_urls: res.success?.map((f) => f.original),
        },
      });
      runInAction(() => {
        this._uploadedVideos = res.success;
        this._preUploadStatus = 2;
        this._preUploadFiles = [];
      });
      this.handleSave();
    });

    request.events.on("error", (res) => {
      pushDataLayerEvent({
        event: "gemhub:camera:media:preupload:error",
        event_params: {
          media_filenames: dataToSend?.getAll("files").map((f: any) => f.name),
          http_status_code: res.details.statusCode,
          error_message: res.error,
        },
      });
      this._uploadedVideos = [];
      this._preUploadStatus = 3;
      runInAction(() => {
        if (this._savedStatus === 1) {
          this._savedStatus = 3;
        } else {
          this._savedStatus = 0;
        }
      });
      console.error("-----------:upload error");
    });
  }

  public async uploadMedia(
    data: ExtractApiRequest<typeof postUploadMedia>,
    query: ObjectType = {},
    params: ObjectType = {},
  ) {
    if (this.uploadingProgress) return Promise.reject("Media already uploading");

    let dataToSend: Nullable<FormData> = data instanceof FormData ? data : null;
    if (!(data instanceof FormData)) {
      const uploadIDsArr: string[] = [];
      let productTypeStr = "";
      const formData = new FormData();

      let dataFiles = data;
      if (data && data.length > 1) {
        dataFiles = data.filter((d) => !d.types.includes("video"));
        if (data.length > dataFiles.length && this._uploadedVideos?.length) {
          this._uploadedVideos.forEach((video) => {
            formData.append("temp_media_paths", video.original);
            if (typeof this.savedSelectedSKUOption !== "undefined") {
              const skuToSet =
                typeof this.savedSelectedSKUOption === "number"
                  ? JSON.stringify(this.savedSelectedSKUOption)
                  : "";
              formData.append("sku", skuToSet);
            }
          });
        }
      }

      dataFiles.forEach(({ uploadIDs, files, types, sku, productType = "" }) => {
        formData.append("files", files);
        formData.append("types", types);
        if (!productTypeStr && productType) {
          productTypeStr = productType;
        }

        if (typeof sku !== "undefined") {
          const skuToSet = typeof sku === "number" ? JSON.stringify(sku) : "";
          formData.append("sku", skuToSet);
        }
        // if (!formData.get("name")) {
        //   formData.append("name", title);
        // }

        if (uploadIDs) uploadIDsArr.push(uploadIDs);
      });

      if (uploadIDsArr.length) formData.append("uploadIDs", JSON.stringify(uploadIDsArr));
      if (productTypeStr) {
        formData.append("productType", productTypeStr);
      }
      if (params.description) {
        formData.append("description", params.description);
      }
      if (params.detailedTitle) {
        formData.append("detailedTitle", params.detailedTitle);
      }
      if (params.parameters) {
        formData.append("parameters", JSON.stringify(params.parameters));
      }
      const mediaMetaDatas =
        data.length > 0 && data.filter((f) => f.metaData).length > 0
          ? data.map((f) => f.metaData)
          : null;
      if (mediaMetaDatas) {
        formData.append("mediaMetaDatas", JSON.stringify(mediaMetaDatas));
      }
      dataToSend = formData;
    }

    if (!dataToSend) return Promise.reject("Invalid data passed");

    runInAction(() => {
      this._uploadingMedia = true;
      this.updateMediaFilter("page", 1);
    });

    const request = postUploadMedia.getRequest({
      data: dataToSend,
      queryParams: { ...query, ...this._getRequestFilters() },
    });

    this._saveRequest = request;

    request.events.on("requestProgress", (res) => {
      runInAction(() => {
        this._uploadingProgress = res;
      });
    });
    const result = await request.fetch();
    if (result?.error && result.error?.originalError?.code === "limit:storage_exceeded") {
      const { modalsStore } = useStores();
      runInAction(() => {
        modalsStore.open("CloudStorageFullModal", {
          formattedMessage: result.error.formattedMessage,
        });
      });
    }
    if (result.success) {
      this._setMediaList(result.success);
      runInAction(() => {
        this._uploadedVideos = [];
      });
    }
    runInAction(() => {
      this._uploadingMedia = false;
      this._uploadingProgress = null;
    });
    return result;
  }

  public async putSaveAIResult(id: number, isSaveAsNewMedia = false) {
    runInAction(() => {
      this._loadingAIResult = true;
    });

    const result = await putSaveAIResult
      .getRequest({
        params: { id },
        // NOTE: send undefined (nothing) if false due to backend requirement.
        queryParams: { isSaveAsNewMedia: isSaveAsNewMedia || undefined },
      })
      .fetch();

    runInAction(() => {
      this._loadingAIResult = false;
    });

    return result;
  }

  public async createMediaCollection(medias: ExtendedMediaModel[]) {
    const { success } = await postCreateMediaCollection
      .getRequest({
        data: {
          name: "Multi media link",
          description: "",
          mediaIDs: medias.map(({ id }, i) => [id, i]),
        },
      })
      .fetch();

    if (success) return success;
  }

  /* Requests ↑ */

  /* UI State ↓ */
  public setFilters(filters: MediaFilters, refresh?: "load" | "upload") {
    if (refresh === "load") return this.loadMediaList(filters);
    if (refresh === "upload") return this.uploadMediaList(filters);
    this._filters = filters;
  }

  public updateMediaFilters(filters: Partial<MediaFilters>, refresh?: "load" | "upload") {
    const newFilters = { ...toJS(this.filters), ...filters };
    if (refresh === "load") return this.loadMediaList(newFilters);
    if (refresh === "upload") return this.uploadMediaList(newFilters);
    this.setFilters(newFilters);
  }

  public updateMediaFilter<T extends keyof MediaFilters>(
    filter: T,
    value: MediaFilters[T],
    refresh?: "load" | "upload",
  ) {
    const newFilters = toJS(this.filters);
    newFilters[filter] = value;
    if (refresh === "load") return this.loadMediaList(newFilters);
    if (refresh === "upload") return this.uploadMediaList(newFilters);
    this.setFilters(newFilters);
  }

  public toggleMediaList(medias: MaybeArray<ExtendedMediaModel>, field: "selected" | "loading") {
    toArrayOptions(medias).forEach((media) => {
      media.extended[field] = !media.extended[field];
    });
  }

  public selectAllMediaList(field: "selected" | "loading") {
    this._mediaList.forEach((item) => {
      item.extended[field] = true;
    });
  }

  public unselectAllMediaList(field: "selected" | "loading") {
    this._mediaList.forEach((item) => {
      item.extended[field] = false;
    });
  }

  public updateMediaLocal(
    media: ExtendedMediaModel,
    override: Partial<Omit<ExtendedMediaModel, "id">>,
  ) {
    Object.assign(media, override);
  }

  public updateMediaLinkPrivacyLocal(media: ExtendedMediaModel, isPrivate: boolean) {
    media.link.isPrivate = isPrivate;
  }

  public updateMediaAssignLocal(
    media: ExtendedMediaModel,
    productAssignData: Nullable<Pick<ProductModel, "title" | "_id">>,
  ) {
    this.updateMediaLocal(media, {
      sku: productAssignData?.title,
      sku_id: productAssignData?._id,
    });
  }

  public resetStore() {
    this._filters = this._initialFilters;
    this._loading = false;
    this._mediaList = [];
    this._totalMediaAmount = 0;
    this._resultsMediaAmount = 0;
  }
  /* UI State  ↑  */

  /* Helpers ↓ */
  public extendMedia(media: MediaModel): ExtendedMediaModel {
    if (isObservable(media)) return media as ExtendedMediaModel;
    return extendObservable<MediaModel, ExtendedMediaModel>(media, {
      ...media,
      extended: {
        selected: false,
        loading: false,
      },
    });
  }

  public getMediaById(mediaId: MediaModel["id"]) {
    return this._mediaList.find(({ id }) => id === mediaId);
  }

  public getMediasByIds(mediaIds: MaybeArray<MediaModel["id"]>) {
    const medias: ExtendedMediaModel[] = [];

    toArrayOptions(mediaIds).forEach((mediaId) => {
      const media = this._mediaList.find(({ id }) => id === mediaId);

      if (media) medias.push(media);
    });

    return medias;
  }

  private _setMediaList(response: PaginationList<MediaModel>) {
    this._totalMediaAmount = response.total_items;
    this._resultsMediaAmount = response.filtered_items;
    this._mediaList = response.rows.map((media) => this.extendMedia(media));
  }

  private _getRequestFilters(): MediaFiltersQueryType {
    const filtersCopy: MediaFiltersQueryType = toJS(this._filters);

    if (filtersCopy.user_id === "all") filtersCopy.user_id = null;
    if (filtersCopy.type === "all") filtersCopy.type = null;
    if (filtersCopy.sort === "all") filtersCopy.sort = null;

    return filtersCopy;
  }

  public extendCategories(media: CategoriesModel, redundant = false): ExtendedCategoriesModel {
    if (isObservable(media)) return media as ExtendedCategoriesModel;
    return extendObservable<CategoriesModel, ExtendedCategoriesModel>(media, {
      ...media,
      extended: {
        redundant,
      },
    });
  }

  private _setCategoriesList(response: Array<CategoriesModel>) {
    this._categoriesList = response.map((media) =>
      this.extendCategories(
        media,
        response.filter(
          (f) =>
            f?.item_details?.cameraName === media?.item_details?.cameraName ||
            f?.item_details?.deviceName === media?.item_details?.deviceName,
        ).length > 1,
      ),
    );
  }

  private _setMediaPendingUploadList(response: PaginationList<MediaModel>) {
    this._totalMediaPendingUploadAmount = response.filtered_items;
    this._mediaPendingUploadList = response.rows.map((media) => this.extendMedia(media));
  }

  /* Helpers ↑ */
}
