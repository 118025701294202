import React, { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { toJS } from "mobx";
import { useNavigate } from "react-router-dom";
import {
  Button,
  NativeInteractiveVideo,
  Nullable,
  SvgIcon,
  Video,
  Image,
  clsx,
  useCall,
  ProgressCircle,
  useDidMount,
  useDebounce,
  Heading,
  useDidUpdate,
  useBoolean,
  Info,
} from "@gemlightbox/core-kit";

import { ENVIRONMENT } from "src/config/environment";
import { getUserTagManagerInfoCallback, postBatchRemoveBackground } from "src/api";
import { useStores } from "src/hooks";
import { PendingUploadStatus, ProductImageModel } from "src/models";
import { getMediaSrc, proFeatureTooltip, pushDataLayerEvent } from "src/utils";
import { AR_MEDIA_PAGE, ErrorCodes } from "src/constants";
import { useLimits } from "src/containers/settings/subscriptions/subscriptions.utils";
import { openBackgroundRemovalErrorNotification } from "src/containers/media/media.utils";
import WSConnection from "src/common/socket";
import { colorsArr } from "./product-main-media.constants";

import { ReactComponent as PlusSVG } from "src/external-ts/assets/images/plus-circle-filled-grey.svg";
import { ReactComponent as RemoveBGSVG } from "src/external-ts/assets/images/edit-media/background-icon.svg";
import { ReactComponent as NewBadgeSVG } from "src/external-ts/assets/images/badges/new-badge.svg";
import { ReactComponent as ErrorSVG } from "src/external-ts/assets/images/circle-warning-icon-red.svg";
import { ReactComponent as CheckmarkSVG } from "src/external-ts/assets/images/checkmark-thin-grey.svg";
import { ReactComponent as EditSVG } from "src/external-ts/assets/images/pencil-grey.svg";
import { ReactComponent as PendingUpSVG } from "src/external-ts/assets/images/pending-upload-icon.svg";
import { ReactComponent as ImageSVG } from "src/external-ts/assets/images/products/image-icon.svg";
import { ProUserUpgradeModal } from "src/external-ts/components/business";
import { MediaTags } from "src/containers/media/media-tags";
import styles from "./product-main-media.module.css";
import stylesMediaTags from "src/containers/media/media-tags/media-tags.module.css";

const devEnvironment = ENVIRONMENT !== "production" && ENVIRONMENT !== "staging";

export type ProductMainMediaProps = {
  canAddMedia: boolean;
  productImage: Nullable<ProductImageModel>;
  onAddMediaClick: VoidFunction;
  onMediaReplace: (
    replaceId: ProductImageModel["id"],
    newMedia: ProductImageModel,
    asNew: boolean,
  ) => void;
  onSetMainMedia?: VoidFunction;
};

export const ProductMainMedia: React.FC<ProductMainMediaProps> = observer(
  ({
    canAddMedia,
    productImage,
    onAddMediaClick,
    onMediaReplace,
    onSetMainMedia,
  }: ProductMainMediaProps) => {
    const navigate = useNavigate();

    const debounce = useDebounce(3000);

    const {
      localeStore,
      tooltipStore,
      userStore,
      notificationStore,
      mediaStore,
      subscriptionsStore,
    } = useStores();

    const { canBlinks } = useLimits();

    const [tempSrc, setTempSrc] = useState("");

    const requestIdRef = useRef("");
    const removeBGIdRef = useRef(0);
    const [progress, setProgress] = useState(0);
    const [selectedEditColor, setSelectedEditColor] = useState("white");
    const [isInEditColor, setIsInEditColor] = useState(false);
    const [error, setError] = useState(false);
    const [proUserUpgradeContent, setProUserUpgradeContent] = useState("");
    const proUserUpgradeBool = useBoolean(false);

    const removeBackgroundCall = useCall(postBatchRemoveBackground);
    removeBackgroundCall.onCallStart(({ headers }) => {
      setProgress(0);
      requestIdRef.current = headers["x-request-id"];
    });
    removeBackgroundCall.onCallSuccess(async (data) => {
      const link = data.links?.[0];
      if (!link) return;

      // Note: silently updating userMe due to usage limits
      userStore.loadUserMeSilently();

      // Note: pushing event to google tag
      getUserTagManagerInfoCallback((response) => {
        pushDataLayerEvent({
          event: "remove_bg_ai",
          user_id: response.user_id,
          account_type: response.account_type,
          is_trial: response.isTrial,
        });
      });

      removeBGIdRef.current = data.id;
      setTempSrc(link);
      setIsInEditColor(true);
    });
    removeBackgroundCall.onCallError(({ originalError, formattedMessage }) => {
      if (originalError?.code === ErrorCodes.MEDIA_BACKGROUND_REMOVE_LIMIT_EXCEEDED) {
        openBackgroundRemovalErrorNotification(formattedMessage);
      }

      setError(true);
      debounce.debounce(() => setError(false));
    });

    const removingBG = removeBackgroundCall.submitting;

    const src = tempSrc || getMediaSrc(productImage, "medium");

    const isUploading = productImage && productImage?.status === PendingUploadStatus.pending;
    const isVideo = productImage?.type === "video" && !!src;
    const isVideo360 = productImage?.type === "video360" && !!src;
    const isVideoType = isVideo || isVideo360;
    const isImage = productImage?.type === "image" && !!src;
    const isBlink = productImage?.type === "blink" && !!src;
    const isPdf = productImage?.type === "pdf" && !!src;

    const canShowRemoveBG =
      !removingBG &&
      !isInEditColor &&
      isImage &&
      !productImage.metaData?.isAiBackgroundRemoval &&
      !productImage.metaData?.isAiRetouch &&
      !productImage.metaData?.isEclipseMode &&
      !isUploading;

    // NOTE: Using 'productImage?.arData?.arTemplateId' due to not all blinks are editable, but only one's,
    // that has necessary fields
    const canShowEditMediaBlink = isBlink && !!productImage?.arData?.arTemplateId;
    const canShowEditMediaImage = isImage;
    const canShowEdit = false;
    // const canShowEdit = canShowEditMediaBlink || canShowEditMediaImage;

    const isEditDisabled = isBlink && !canBlinks;

    const handleCancelEdit = () => {
      removeBackgroundCall.abortCall();
      debounce.resetDebounce();
      setTempSrc("");
      setError(false);
      setIsInEditColor(false);
    };

    const handleRemoveBG = (color = "white") => {
      if (!productImage) return;

      const formData = new FormData();
      formData.append("background_color", color);
      formData.append("media_ids", productImage.id.toString());
      removeBackgroundCall.submit({ data: formData });

      setSelectedEditColor(color);
    };

    const handleRemoveBGClick = () => {
      if (
        !userStore.isSubscribed &&
        (userStore.userUsage?.countBackgroundRemoval ?? 0) >=
          subscriptionsStore.limits.count_removal_background
      ) {
        setProUserUpgradeContent(localeStore.t('common["pro-user"]["remove-bg-upgrade-text"]'));
        proUserUpgradeBool.setTruthy();
        return;
      }
      handleRemoveBG();
    };

    const handleColorChange = (color: string) => () => handleRemoveBG(color);

    const handleEditPointerEnter = (e: React.PointerEvent<HTMLButtonElement>) => {
      if (!isEditDisabled) return;
      proFeatureTooltip(e.currentTarget, {
        position: "left",
      });
    };
    const handleEditPointerLeave = () => {
      if (!isEditDisabled) tooltipStore.close();
    };
    const handleEditClick = () => {
      if (!productImage) return;
      if (canShowEditMediaImage) navigate(`/media/${productImage.id}/edit`);
      if (canShowEditMediaBlink) {
        navigate(AR_MEDIA_PAGE.path, {
          state: {
            media: toJS(productImage),
            type: "media",
            redirect: location.pathname,
          },
        });
      }
    };

    const handleSaveImage = async (asNew: boolean) => {
      if (!removeBGIdRef.current || !productImage) return;
      const result = await mediaStore.putSaveAIResult(removeBGIdRef.current, asNew);

      if (result.success) {
        handleCancelEdit();
        onMediaReplace(productImage.id, result.success.rows[0] as any, asNew);
      }
    };

    const handleSaveImageClick = () => {
      notificationStore.open({
        title: localeStore.t('common.media["how-to-save"]'),
        icon: "questionMarkRoundedFilled",
        confirmAppearance: "primary",
        confirmText: localeStore.t('common.buttons["save-changes"]'),
        cancelText: localeStore.t('common.media["save-as-new"]'),
        onOk: () => handleSaveImage(false),
        onCancel: () => handleSaveImage(true),
      });
    };

    useDidMount(() => {
      const userId = userStore.userMe?.user._id;
      if (!userId) return;

      const progressWS = new WSConnection(`/user/${userId}`, userStore.token);

      progressWS.on("progress/UPDATE", ({ payload }: any) => {
        if (requestIdRef.current === payload.requestID) {
          setProgress(payload.progress);
        }
      });

      return () => progressWS.destroy();
    });

    useDidUpdate(handleCancelEdit, [productImage?.id]);

    return (
      <div data-cy="product-main-media" className={styles.productMainMediaContainer}>
        {canAddMedia && (
          <Button
            data-cy="add-media-to-product"
            className={styles.addMediaButton}
            appearance="secondary"
            onClick={onAddMediaClick}
          >
            <SvgIcon icon={PlusSVG} />
            {localeStore.t('common.buttons["add-media"]')}
          </Button>
        )}

        {isPdf && (
          <iframe height="100%" width="100%" src={`${productImage?.file.original}#toolbar=1`} />
        )}

        {isVideo && !isUploading && (
          <Video src={src} className={styles.videoContainer} videoClassName={styles.videoContent} />
        )}
        {isVideo360 && !isUploading && (
          <NativeInteractiveVideo className={styles.interactiveVideoContainer} src={src} />
        )}
        {(isImage || isBlink || (isUploading && isVideoType)) && (
          <Image
            key={src} // NOTE: pass src as key, so no flicks will be visible due to cache
            src={src}
            className={`${userStore.backgroundFill}Background`}
            objectFit="contain"
            loading="eager"
          />
        )}

        {isUploading && (
          <div className={clsx(styles.pendingUpWrapper, { [styles.pendingUpText]: !!src })}>
            {!src && <SvgIcon icon={PendingUpSVG} size={128} />}
            <span>
              {localeStore
                .t('media.modals["pending-upload-media-sidebar"]["pending-up-from"]')
                .replace(
                  localeStore.t('media.modals["pending-upload-media-sidebar"]["unknown-source"]'),
                  productImage?.metaData?.cameraName ||
                    productImage?.metaData?.deviceName ||
                    localeStore.t('media.modals["pending-upload-media-sidebar"]["unknown-source"]'),
                )}
            </span>
          </div>
        )}

        {canShowEdit && !isInEditColor && !isUploading && (
          <Button
            data-cy="edit-media"
            className={styles.editMediaBtn}
            appearance="tertiaryOutlined"
            onPointerEnter={handleEditPointerEnter}
            onPointerLeave={handleEditPointerLeave}
            onClick={handleEditClick}
            disabled={isEditDisabled}
          >
            <SvgIcon icon={EditSVG} />
            {localeStore.t('common.media["edit-media"]')}
          </Button>
        )}
        {removingBG && (
          <div className={styles.messageContainer}>
            <ProgressCircle progress={progress} />
          </div>
        )}

        {error && !removingBG && (
          <div className={styles.messageContainer}>
            <SvgIcon icon={ErrorSVG} size={64} />
            <Heading tag="h2">{localeStore.t('common.warnings["something-wrong"]')}</Heading>
          </div>
        )}

        {isInEditColor && !removingBG && (
          <div className={clsx(styles.editColorsWrapper, { [styles.videoMediaType]: isVideoType })}>
            {colorsArr.map((color) => (
              <Button
                data-cy={`${color}-color-bg`}
                key={color}
                className={styles.colorBtn}
                appearance="tertiaryOutlined"
                size="small"
                style={{ backgroundColor: color }}
                onClick={handleColorChange(color)}
              >
                {selectedEditColor === color && (
                  <div className={styles.colorItemOverlay}>
                    <SvgIcon icon={CheckmarkSVG} size={16} />
                  </div>
                )}
              </Button>
            ))}

            {!isVideoType && (
              <Button appearance="primaryOutlined" size="small" onClick={handleEditClick}>
                <SvgIcon icon={EditSVG} />
              </Button>
            )}

            <Button
              data-cy="cancel-btn"
              className={styles.cancelColorBtn}
              appearance="tertiaryOutlined"
              size="small"
              onClick={handleCancelEdit}
            >
              {localeStore.t("common.buttons.cancel")}
            </Button>
            <Button
              data-cy="save-btn"
              className={styles.saveColorBtn}
              size="small"
              onClick={handleSaveImageClick}
            >
              {localeStore.t("common.buttons.save")}
            </Button>
          </div>
        )}

        {productImage && !isInEditColor && (
          <div
            className={clsx(styles.mediaTags, {
              [styles.videoMediaType]: isVideoType && !isUploading,
            })}
          >
            <MediaTags
              metaData={productImage.metaData}
              mediaType={productImage.type}
              isShowInfo={true}
              isCreatedByMobile={!productImage?.arData}
            />
            {typeof onSetMainMedia === "function" && (
              <div
                className={clsx(stylesMediaTags.mediaTagWrapper, styles.mediaMainTag)}
                onClick={onSetMainMedia}
              >
                <Info
                  className={stylesMediaTags.infoTooltip}
                  appearance="secondary"
                  position="top"
                  withAngle
                  offsetY={4}
                  icon={ImageSVG}
                  size={16}
                >
                  {localeStore.t('products["product-edit-cover-photo"]["cover-photo"]')}
                </Info>
              </div>
            )}
          </div>
        )}

        {canShowRemoveBG && (
          <Button
            data-cy="remove-bg-btn"
            className={clsx(styles.removeBGBtn, { [styles.videoMediaType]: isVideoType })}
            appearance="primaryOutlined"
            onClick={handleRemoveBGClick}
          >
            <SvgIcon className={styles.betaBadge} icon={NewBadgeSVG} size={20} />
            <SvgIcon icon={RemoveBGSVG} />
            {localeStore.t('common.buttons["remove-background"]')}
            {!userStore.isSubscribed &&
              `(${Math.max(
                0,
                subscriptionsStore.limits.count_removal_background -
                  (userStore.userUsage?.countBackgroundRemoval ?? 0),
              )}/${subscriptionsStore.limits.count_removal_background})`}
          </Button>
        )}

        <ProUserUpgradeModal
          visible={proUserUpgradeBool}
          onClose={proUserUpgradeBool.setFalsy}
          content={proUserUpgradeContent}
        ></ProUserUpgradeModal>
      </div>
    );
  },
);

export default ProductMainMedia;
