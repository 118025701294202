import React from "react";
import { observer } from "mobx-react-lite";
import { RefModel, useDidMount, VirtualizedItem } from "@gemlightbox/core-kit";

import { useStores } from "src/hooks";
import { NUMBER_OF_MEDIA_TO_PREVIEW } from "../../../media-preview";
import { MediaWithoutSKURow } from "./media-without-sku-row";
import { ProductsPendingUploadRow } from "./products-pending-upload-row";
import { ProductsTableRow } from "./products-table-row";

import styles from "./products-table-body.module.css";

export interface ProductsTableBody {
  noSkuWidth: number;
  virtualizedItems: VirtualizedItem[];
  forwardRef?: RefModel<HTMLDivElement>;
}

export const ProductsTableBody: React.FC<ProductsTableBody> = observer(
  ({ noSkuWidth, virtualizedItems, forwardRef }) => {
    const { productsStore, mediaStore } = useStores();

    useDidMount(() => {
      mediaStore.loadMediaList({ limit: NUMBER_OF_MEDIA_TO_PREVIEW, withoutSku: true });
      productsStore.loadProductsPendingUploadList();
    });

    const shouldRenderNoSKURow = mediaStore.resultsMediaAmount > 0;
    const shouldRenderPendingUploadRow = productsStore.totalProductsPendingUploadAmount > 0;
    let offset = 0;
    offset += +shouldRenderNoSKURow;
    const renderNoSKURowIndex = offset - 1;
    offset += +shouldRenderPendingUploadRow;
    const renderPendingUploadRowIndex = offset - 1;

    return (
      <div ref={forwardRef} className={styles.contentContainer}>
        {virtualizedItems.map(({ index, styles }) => {
          const productStyles = {
            ...styles,
            minWidth: "100%",
            width: "unset",
          };
          if (shouldRenderNoSKURow && index === renderNoSKURowIndex) {
            return (
              <MediaWithoutSKURow
                key="no-media-row"
                style={{ ...productStyles, width: noSkuWidth - 24 }}
                data-cy="media-without-sku"
              />
            );
          }

          if (shouldRenderPendingUploadRow && index === renderPendingUploadRowIndex) {
            return (
              <ProductsPendingUploadRow
                key="pending-uplod-media-row"
                style={{ ...productStyles, width: noSkuWidth - 24 }}
                data-cy="media-pending-uplod"
              />
            );
          }

          const product = productsStore.productsList[index - offset];
          if (!product) return;

          return <ProductsTableRow style={productStyles} key={product._id} product={product} />;
        })}
      </div>
    );
  },
);
