import React, { useMemo, useState } from "react";
import { FormikHelpers } from "formik";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import {
  useQuery,
  Form,
  FormButtons,
  FormField,
  Button,
  Image,
  Heading,
  Typography,
  queryStringify,
} from "@gemlightbox/core-kit";

import { useIsLinkEntrance, useStores } from "src/hooks";
import { Logo } from "src/external-ts/components";
import { CAMERA_PAGE, DEFAULT_PAGE, SETUP_DOWNLOAD_PAGE } from "src/constants";
import { ErrorCodes } from "src/constants";
import { AuthAside } from "../auth-aside";
import { AuthContainer } from "../auth-container";
import { AuthMain } from "../auth-main";
import { MobileSignInInfo } from "./mobile-sign-in-info";
import { LoginForm, LoginQueryType } from "./login.types";
import { signInValidation } from "./login.utils";

import previewPNG from "src/external-ts/assets/images/auth/preview-min.png";
import styles from "./login.module.css";
import { UserMeModel } from "src/models";

export interface LoginProps {
  shopifyToken?: string;
}

export const LoginPage: React.FC<LoginProps> = observer(({ shopifyToken }: LoginProps) => {
  const navigate = useNavigate();

  const { userStore, localeStore } = useStores();

  const { query } = useQuery<LoginQueryType & { r: string }>();

  const { isLinkEntrance } = useIsLinkEntrance();

  const initialValues: LoginForm = useMemo(() => {
    return {
      email: query.email || "",
      password: "",
    };
  }, []);

  const handleForgotPassword = () => navigate("/auth/reset/request");

  const handleSignUp = () => navigate("/auth/signup" + queryStringify(query));

  const handleScan = () => navigate("/scan" + queryStringify(query));

  const [loading, setLoading] = useState(false);

  const handleLogin = async (data: LoginForm, helpers: FormikHelpers<any>) => {
    setLoading(true);
    const result = await userStore.login({ ...data, shopifyToken });
    if (!result) return;
    if (result.status === "success") {
      if (userStore.redirectToGemhubIfNeeded(result.success as unknown as Nullable<UserMeModel>)) {
        return;
      }
      // app 用户
      //   isLinkEntrance true
      //     跳转 CAMERA_PAGE
      //   isLinkEntrance false
      //     跳转 DEFAULT_PAGE
      // 非 app 用户
      //   isLinkEntrance true
      //     跳转 SETUP_DOWNLOAD_PAGE
      //   isLinkEntrance true
      //     跳转 DEFAULT_PAGE

      if (window.$platform.isApp) {
        if (isLinkEntrance) {
          return navigate(CAMERA_PAGE.path);
        } else {
          return navigate(DEFAULT_PAGE.path);
        }
      } else {
        if (isLinkEntrance) {
          userStore.logout(true);
          return navigate(SETUP_DOWNLOAD_PAGE.path);
        } else {
          if (query.r) {
            return navigate(query.r);
          } else {
            return navigate(DEFAULT_PAGE.path);
          }
        }
      }
    } else {
      setLoading(false);
    }

    if (result.error.statusCode === 401) {
      helpers.setFieldError("password", localeStore.t('auth.login["invalid-credentials"]'));
    }

    if (result.error.originalError.code === ErrorCodes.NOT_CONFIRMED_MOBILE_EMAIL_ERROR) {
      navigate(`/auth/terms-of-service?email=${encodeURIComponent(data.email.toLowerCase())}`);
    }
  };

  return (
    <AuthContainer>
      <AuthMain>
        <Logo />
        <section className={styles.loginSection}>
          <Heading className={styles.header} tag="h2" color="textSecondary">
            {localeStore.t("auth.login.title")}
          </Heading>
          <Typography className={styles.description} size="small" color="textTertiary">
            {localeStore.t("auth.login.subtitle")}
          </Typography>
          <Form
            contentClassName={styles.formContent}
            initialValues={initialValues}
            onSubmit={handleLogin}
            validate={signInValidation}
          >
            <FormField
              className={styles.emailContainer}
              type="text"
              name="email"
              label={localeStore.t("auth.login.fields.email.label")}
              placeholder={localeStore.t("auth.login.fields.email.placeholder")}
              appearance="primaryV2"
              data-cy="auth-email-field"
            />
            <FormField
              type="password"
              name="password"
              label={localeStore.t("auth.login.fields.password.label")}
              placeholder={localeStore.t("auth.login.fields.password.placeholder")}
              appearance="primaryV2"
              data-cy="auth-password-field"
            />
            <div className={styles.forgotWrapper}>
              <Button
                appearance="secondaryGhost"
                onClick={handleForgotPassword}
                data-cy="auth-forgot-password-button"
              >
                {localeStore.t('auth.login.buttons["forgot-password"]')}
              </Button>
            </div>
            <FormButtons align="column">
              <Button loading={loading} type="submit" data-cy="auth-sign-in-button">
                {localeStore.t('auth.login.buttons["sign-in"]')}
              </Button>
              <Button
                appearance="primaryOutlined"
                onClick={handleScan}
                data-cy="auth-qr-sign-in-button"
              >
                {localeStore.t('auth.login.buttons["mobile-application-qr-sign-in"]')}
                <MobileSignInInfo />
              </Button>
            </FormButtons>
            <div className={styles.noAccountWrapper}>
              <Typography size="extraSmall" color="textTertiary">
                {localeStore.t('auth.login["no-account-text"]')}
              </Typography>
              <Button
                appearance="primaryGhost"
                onClick={handleSignUp}
                data-cy="auth-sign-up-button"
              >
                {localeStore.t('auth.login.buttons["sign-up"]')}
              </Button>
            </div>
          </Form>
        </section>
      </AuthMain>
      <AuthAside>
        <Image className={styles.preview} src={previewPNG} loading="eager" />
      </AuthAside>
    </AuthContainer>
  );
});

export default LoginPage;
